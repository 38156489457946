import React, { useEffect, useState, useContext } from "react"
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import AuthContext from "../../store/AuthContext";
import Alert from "../../components/Alert";
import Loader from "../../components/Loader";

import CustomToast from "../../components/CustomToast";

const Login = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const { state: locationState } = useLocation();
    const location = useLocation();

    const [existingUserChecking, setexistingUserChecking] = React.useState(true);
    const [companyName, setCompanyName] = useState('');
    const [empNo, setEmpNo] = useState('');
    const [password, setPassword] = useState('');
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState('');
    const [visiblePopup, setVisiblePopup] = useState(false);
    const [showToast, setShowToast] = useState(false)
    const hideToast = () => {
        setShowToast(false)
    }
    useEffect(() => {
        const authToken = authCtx.getTokenFromStorage();

        const queryParams = new URLSearchParams(location.search);

        if (authToken) {
            setLoader(true);
            fetch(`${apiUrl}/auth/refresh`,
                {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "authorization": 'bearer ' + localStorage.getItem('auth_token')
                    }
                }).then(async (e) => {

                    if (e.status === 200) {
                        const response = await e.json();
                        authCtx.login(response.user);
                        if (queryParams.has('path')) {
                            navigate(queryParams.get('path'));
                        }
                        else if (locationState != null) {
                            navigate(locationState.path);
                        }
                        else {
                            navigate('/');
                        }
                        setLoader(false);

                    } else {
                        setLoader(false);
                        authCtx.logout();

                        setexistingUserChecking(false);
                    }

                }).catch((e) => {
                    authCtx.logout();
                    setLoader(false);
                    setexistingUserChecking(false);
                });
        }
        else if (queryParams.has('handshake_token') && queryParams.has('company_name') && queryParams.has('emp_no')) {
            console.log("inside");
            fetch(`${apiUrl}/auth/verify-handshake`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',  // This is important
                },
                body: JSON.stringify({
                    handshakeToken: queryParams.get('handshake_token'),
                    companyName: queryParams.get('company_name'),
                    empNo: queryParams.get('emp_no'),
                    appName: 'SFMS-Web',
                    appVersion: 1.0,
                    devicePlatform: 'web',
                    deviceName: window.navigator.userAgent.substring(0, 50)
                })
            }).then(async (e) => {
                if (e.status === 200) {
                    const response = await e.json();
                    authCtx.login(response.user);

                    if (queryParams.has('path')) {
                        navigate(queryParams.get('path'));
                    }
                    else if (locationState != null) {
                        navigate(locationState.path);
                    }
                    else {
                        navigate('/')
                    }
                }
                else {
                    setLoader(false);
                    const response = await e.json();
                    setShowToast({
                        status: 'Fail',
                        messages: [response.errors],
                        hideToast: hideToast
                    });
                }
            }).catch((error) => {
                setexistingUserChecking(false);
                navigate('/auth/login');
            })
        } else {
            setexistingUserChecking(false);
        }
    }, []);


    // company select on change function
    const handleSelectChange = (event) => {
        setCompanyName(event.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        try {
            fetch(`${apiUrl}/auth/login`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        companyName: companyName,
                        empNo: empNo,
                        password: password,
                        appName: "SFMS-Web"
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "authorization": 'bearer ' + localStorage.getItem('auth_token')
                    }
                }
            ).then(async (response) => {

                if (response.status === 200) {
                    const result = await response.json();
                    authCtx.login(result.user);
                    setLoader(false);
                    navigate("/");


                } else {
                    // showHttpStatusBasedErrors(response);
                    setLoader(false);
                    const result = await response.json();
                    setShowToast({
                        status: 'Fail',
                        messages: result.errors,
                        hideToast: hideToast
                    });
                }

            }).catch((error) => {
                // showRequestFailError(error);
                setLoader(false);
                setShowToast({
                    status: 'Fail',
                    messages: [error.message],
                    hideToast: hideToast
                });

            });

        }
        catch (error) {
            setLoader(false);
            setShowToast({
                status: 'Fail',
                messages: [error.message],
                hideToast: hideToast
            });
        }
    }

    return (
        <>
            {/* {visiblePopup && (<Alert messageType={"error"} message={message} setVisiblePopup={setVisiblePopup} />)} */}
            <div className="w-full flex justify-center">
                {showToast && <CustomToast
                    status={showToast.status}
                    messages={showToast.messages}
                    hideToast={hideToast} />}
            </div>
            {loader ?
                (<Loader />) :
                (<div className="min-h-screen flex justify-center items-center w-full bg-gray-100 p-3">
                    {/* Card BG */}
                    <div className=" flex  lg:w-1/2 bg-white shadow-xl rounded-xl p-3 border border-gray-300">
                        {/* Image half division */}
                        <div className="lg:block hidden lg:w-1/2 ">
                            <img className="rounded-lg" alt="power grid" src="../../img/power grid login.jpg" />
                        </div>
                        <div title="Outage Management System" className="lg:w-1/2  md:pl-10 md:pr-8  w-full px-2 flex flex-col justify-center  items-center">
                            <div className="flex justify-center items-center">
                                <p className=" pr-1 pl-2 text-[50px] font-bold bg-clip-text bg-gradient-to-r from-sky-800 via-orange-700 to-orange-500 text-transparent ">
                                    OMS
                                </p>
                                <div className="flex flex-col leading-3">
                                    <span className=" mt-1 text-gray-800 text-[11px] font-bold">
                                        OUTAGE
                                    </span>
                                    <span className="text-gray-800 text-[11px] font-bold">
                                        MANAGEMENT
                                    </span>
                                    <span className="text-gray-800 text-[11px] font-bold">
                                        SYSTEM
                                    </span>
                                </div>
                            </div>
                            {/* <img className="rounded-lg h-32" src="./img/gprd.png" /> */}
                            {/* <p className="text-3xl font-bold text-gray-800 mt-2">Welcome</p> */}
                            {/* <p className="text-sm font-semibold text-orange-600 mt-1">To Outage Management System</p> */}

                            <form className="mt-6 flex flex-col gap-4 w-full" onSubmit={handleSubmit} >
                                <select name="companyName" onChange={handleSelectChange} value={companyName} className="p-2 border border-gray-400 focus:ring-slate-600 rounded-lg text-gray-500" required>
                                    <option value=''>Select Company</option>
                                    <option value="GUVNL">GUVNL</option>
                                    <option value="GETCO">GETCO</option>
                                    <option value="DGVCL">DGVCL</option>
                                    <option value="MGVCL">MGVCL</option>
                                    <option value="PGVCL">PGVCL</option>
                                    <option value="UGVCL">UGVCL</option>
                                </select>

                                <input type="text" name="empNo" className="p-2 border border-gray-400 focus:ring-0 rounded-lg text-gray-500" placeholder='Enter Username' onChange={(e) => setEmpNo(e.target.value)} value={empNo} required />

                                <div className='relative'>
                                    <input type="password" name="password" className="w-full p-2 border border-gray-400 focus:ring-gray-300  rounded-lg text-gray-500" placeholder='Enter Password' onChange={(e) => setPassword(e.target.value)} required />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="cursor-pointer bi bi-eye text-gray-400 absolute top-1/2 right-3 -translate-y-2" viewBox="0 0 16 16">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                    </svg>
                                </div>
                                <p className="-mt-3 underline cursor-pointer text-right text-xs font-semibold text-gray-700"> Forgot Password?</p>
                                <button type="submit" className="p-2 mt-2 bg-orange-600 font-semibold text-white rounded-lg hover:scale-105 duration-200 hover:bg-orange-700" >Login</button>
                                <div className="mt-3 grid grid-cols-3 items-center justify-center">
                                    <hr />
                                    <p className="text-center text-sm font-medium text-indigo-700 space-x-1">Developed By</p>
                                    <hr />
                                </div>
                                <div className="flex text-sm text-gray-600 font-bold justify-center">
                                    {/* <span className="font-semibold cursor-pointer">GPRD | GUVNL</span> */}
                                    <img className="rounded-lg h-28" title="GPRD | GUVNL" alt="GPRD LOGO" src="../../img/gprd.png" />
                                </div>
                            </form>

                        </div>
                    </div >
                </div >)
            }
        </>
    )
}

export default Login;