import React,{createContext, useState} from "react";

const AuthContext = createContext({
    user:{},
    login:(newUser)=>{},
    logout:()=>{},
    getTokenFromStorage:()=>{},
    hasPermission:(checkPermission)=>{}
});

export const AuthContextProvider = (props)=>{
    
    const [user,setUser] = useState(null);
    
    const login = (newUser) => {
        localStorage.setItem('auth_token',newUser.auth_token);
        setUser(newUser);
    }

    const logout = () => {
        localStorage.removeItem('auth_token');
        setUser(null);
    };
    
    const getTokenFromStorage = () => {
        return localStorage.getItem('auth_token');
    };

    return (
        <AuthContext.Provider 
            value={{
                user: user, 
                login: login,
                logout: logout,
                getTokenFromStorage: getTokenFromStorage,
                // hasPermission: hasPermission
            }} 
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext;