import React, { useEffect } from "react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Search } from "react-bootstrap-icons";
import validator from 'validator'
import moment from 'moment';
import CustomToast from "./CustomToast";
const apiUrl = process.env.REACT_APP_API_URL;

const EditSubstation = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        ssName: '',
        ssEurjaCode: '',
        voltageClass: '',
        sdnEurjaCode: '',
        manageBy: '',
        monitoring: '',
        chargingDate: '',
        coastal: '',
        tribal: '',
        ssCategory: '',
        // sdnTnDCode: '',
        emailAddress: '',
        mobileNo: ''
    });
    async function getSubstation() {

        const response = await fetch(`${apiUrl}/substation/${id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "authorization": 'bearer ' + localStorage.getItem('auth_token')
            }
        });
        if (response.ok) {
            const responseData = await response.json()
            console.log(responseData)
            setFormData((prev) => {
                return {
                    ...prev,
                    ssName: responseData.data.ss_name ?? '',
                    ssEurjaCode: responseData.data.eurja_code ?? '',
                    voltageClass: responseData.data.voltage_class ?? '',
                    sdnEurjaCode: responseData.data.sdn_interface ?? '',
                    manageBy: responseData.data.manage_by ?? '',
                    monitoring: responseData.data.monitoring ?? '',
                    chargingDate: responseData.data.charging_date ?? '',
                    coastal: responseData.data.coastal ?? '',
                    tribal: responseData.data.tribal ?? '',
                    ssCategory: responseData.data.ss_category ?? '',
                    emailAddress: responseData.data?.email_address ?? '',
                    mobileNo: responseData.data.mobile_no ?? ''
                }
            });
            setDivisionSearchQuery(responseData.data.div_name)
            setSubDivisionSearchQuery(responseData.data.MstSubdivision?.subdivision_name)
        }
        else {
            setShowToast({
                status: 'Fail',
                messages: ['Substation data not found'],
                hideToast: hideToast
            });
            setTimeout(() => {
                navigate('/substation')
            }, 3000)
        }
    }

    useEffect(() => {
        getSubstation()
    }, []);


    //Handle onChange event

    const handleInputChange = (e) => {
        setFormData((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    //Toast
    const [showToast, setShowToast] = useState(false)
    const hideToast = () => {
        setShowToast(false)
    }

    // Getco Division Search
    const [divisionSearchQuery, setDivisionSearchQuery] = useState();
    const [showDivisionList, setShowDivisionList] = useState(false)
    const [divisionList, setDivisionList] = useState([]);

    const handleDivisionSearch = async (e) => {

        const searchQuery = e.target.value;
        setDivisionSearchQuery(searchQuery);
        setFormData(prev => ({ ...prev, ssEurjaCode: '' }));
        if (searchQuery.length >= 3) {
            try {
                const response = await fetch(`${apiUrl}/utils/getGetcoDivisionByName?division=${encodeURIComponent(searchQuery)}`, {
                    method: 'GET',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "authorization": "bearer " + localStorage.getItem('auth_token')
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                setDivisionList(data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        else {
            setDivisionList([]);
        }
    }
    const handleSelectedDivision = (divisionName, eurjaCode) => {
        setDivisionSearchQuery(divisionName);
        setFormData((prev) => {
            return { ...prev, ssEurjaCode: eurjaCode.substr(0, 6) }
        })
        setDivisionList([])
    }

    // Subdivision Search
    const [subDivisionSearchQuery, setSubDivisionSearchQuery] = useState();
    const [showSubDivisionList, setShowSubDivisionList] = useState(false)
    const [subDivisionList, setSubDivisionList] = useState([]);
    const handleSubDivisionSearch = async (e) => {

        const searchQuery = e.target.value;
        setSubDivisionSearchQuery(searchQuery);
        setFormData(prev => ({ ...prev, sdnEurjaCode: '' }));
        if (searchQuery.length >= 3) {
            try {
                const response = await fetch(`${apiUrl}/utils/getDiscomHeirarchyByName?subdivision=${encodeURIComponent(searchQuery)}`, {
                    method: 'GET',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "authorization": "bearer " + localStorage.getItem('auth_token')
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                setSubDivisionList(data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        else {
            setSubDivisionList([]);
        }
    }

    const handleSelectedSubDivision = (subDivisionName, eurjaCode) => {
        setSubDivisionSearchQuery(subDivisionName);
        setFormData((prev) => {
            return { ...prev, sdnEurjaCode: eurjaCode }
        })
        setSubDivisionList([])
    }


    // Valid List Value
    const validVoltageClass = ['33', '66', '132', '220', '400']
    const validManageBy = ['Getco', 'Outsource', 'EHT'];
    const validMonitoring = ['Manual', 'SFMS', 'SCADA', 'Private'];
    const validSSCategory = ['AIS', 'GIS', 'HGIS', 'GIS+AIS'];

    const handleSubmit = async () => {
        const today = moment().format('YYYY-MM-DD');
        let errorMessages = [];


        if (validator.isEmpty(formData.ssName)) {
            errorMessages.push("Substation Name is required");
        }
        if (!validator.isLength(formData.ssEurjaCode, { min: 8, max: 8 })) {
            errorMessages.push("Substation Eurja Code must be 8 digits long.");
        }
        if (formData.voltageClass === '') {
            errorMessages.push("Voltage Class is required");
        } else if (!validVoltageClass.includes(formData.voltageClass)) {
            errorMessages.push("Select valid Voltage Class value");
        }
        if (!validator.isLength(formData.sdnEurjaCode, { min: 8, max: 8 })) {
            errorMessages.push("Search and Select Valid Subdivision.");
        }
        if (formData.manageBy === '') {
            errorMessages.push("Manage By is required");
        } else if (!validManageBy.includes(formData.manageBy)) {
            errorMessages.push("Select valid Manage By value");
        }
        if (formData.monitoring === '') {
            errorMessages.push("Monitoring is required");
        } else if (!validMonitoring.includes(formData.monitoring)) {
            errorMessages.push("Select valid Monitoring value");
        }
        if (!formData.chargingDate) {
            errorMessages.push("Charging Date is required");
        } else if (moment(formData.chargingDate).isAfter(today)) {
            errorMessages.push("Charging Date cannot be in the future");
        }
        if (formData.coastal === '') {
            errorMessages.push("Coastal is required");
        }
        if (formData.tribal === '') {
            errorMessages.push("Tribal is required");
        }
        if (formData.ssCategory === '') {
            errorMessages.push("SS Category is required");
        }
        else if (!validSSCategory.includes(formData.ssCategory)) {
            errorMessages.push("Select valid SS Category");
        }
        if (!validator.isEmail(formData.emailAddress)) {
            errorMessages.push("Email Address is required");
        }

        if (!validator.isLength(formData.mobileNo, { min: 10, max: 10 })) {
            errorMessages.push("Mobile Number must have 10 digits");
        }



        if (errorMessages.length > 0) {
            setShowToast({
                status: 'Fail',
                messages: errorMessages,
                hideToast: hideToast
            });

        } else {
            try {
                const response = await fetch(`${apiUrl}/substation/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(formData),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "authorization": "bearer " + localStorage.getItem('auth_token')
                    }
                });

                if (response.ok) {

                    const result = await response.json();

                    setShowToast({
                        status: 'Success',
                        messages: [result.message],
                        hideToast: hideToast
                    });
                    setTimeout(() => {
                        navigate('/substation')
                    }, 2000)


                } else {
                    // Handle errors based on status code
                    const error = await response.json();
                    setShowToast({
                        status: 'Fail',
                        messages: [error.message],
                        hideToast: hideToast
                    });
                }
            } catch (error) {
                setShowToast({
                    status: 'Fail',
                    messages: [error.message],
                    hideToast: hideToast
                });
            }
        }
    };


    return (
        <>
            <div className="w-full flex justify-center">
                {showToast && <CustomToast
                    status={showToast.status}
                    messages={showToast.messages}
                    hideToast={hideToast} />}
            </div>
            <div className="bg-[#F8F8F8] min-h-screen p-2 md:p-5">

                {/* {visiblePopup && <Alert message={message} setVisiblePopup={setVisiblePopup} />} */}
                <div className="max-w-7xl mx-auto bg-white border shadow-md rounded-md">
                    {/* Start Header */}
                    <div className="flex flex-col bg-gray-100 px-4 py-2">
                        <span className="text-base font-semibold leading-7 text-gray-800">
                            Edit Substation
                        </span>
                    </div>
                    {/* End Header */}

                    {/* Start Body */}
                    <div className="w-full flex flex-col gap-1 md:gap-2.5 py-6 px-6 gap-">
                        {/* First row grid */}
                        <div className="grid grid-cols-12  gap-x-4">
                            <div className="col-span-2">
                                <div className="flex flex-col gap-1 w-full">
                                    <label className="font-medium text-[13px]">Substation Name <span className="text-sm text-red-600">*</span></label>
                                    <input type="text" id="ssName" name="ssName" className="w-full px-2 py-[5px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none" value={formData.ssName} onChange={handleInputChange} autoComplete="off" />
                                </div>
                            </div>

                            <div className="col-span-5">
                                <div className="relative flex flex-col gap-1">
                                    <label className="font-medium text-[13px]">
                                        Getco Division Name <span className="text-sm text-red-600">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="division"
                                        autoComplete="off"
                                        className="w-full px-2 py-[5px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none"
                                        placeholder="Search Division & Select from list"
                                        value={divisionSearchQuery}
                                        onChange={handleDivisionSearch}
                                    />
                                    {/* Search Division */}
                                    {divisionList.length > 0 &&
                                        <div className="absolute w-full rounded-md gap-1 flex flex-col border p-2 top-16 z-10 bg-white shadow-lg">
                                            <div className="flex flex-col gap-1 overflow-y-scroll max-h-56">
                                                {divisionList.map((data) => (
                                                    <div className="flex justify-between items-center border px-2 rounded-md bg-blue-50 mt-1 hover:bg-blue-100 cursor-pointer">
                                                        <div className="flex flex-col gap-1 px-1 py-1 rounded-md">
                                                            <div className="text-[11px] font-medium py-2">GETCO / {data.circle_name} / <span className="font-semibold text-[12px] text-orange-600">{data.division_name}</span></div>
                                                        </div>
                                                        <div className="rounded-md font-medium text-[12.5px] bg-orange-600 text-white px-2 py-1 hover:bg-orange-500" onClick={() => { handleSelectedDivision(data.division_name, data.eurja_code) }}>Select</div>
                                                    </div>
                                                ))
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-span-2">
                                <div className="flex flex-col gap-1 w-full">
                                    <label className="font-medium text-[13px]">SS Eurja Code (8 Digits) <span className="text-sm text-red-600">*</span></label>
                                    <input
                                        type="text"
                                        id="ssEurjaCode"
                                        name="ssEurjaCode"
                                        autoComplete="off"
                                        className="w-full px-2 py-[5px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none"
                                        value={formData.ssEurjaCode}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* second row grid */}
                        <div className="grid grid-cols-12 gap-x-4 my-4">
                            <div className="col-span-2">
                                <div className="flex flex-col gap-1 w-full">
                                    <label className="font-medium text-[13px]">Voltage Class <span className="text-sm text-red-600">*</span></label>
                                    <select type="text" id="voltageClass" name="voltageClass" className="w-full px-2 py-[6px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none" value={formData.voltageClass} onChange={handleInputChange}>
                                        <option value="">Select</option>
                                        <option value="33">33</option>
                                        <option value="66">66</option>
                                        <option value="132">132</option>
                                        <option value="220">220</option>
                                        <option value="400">400</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-span-5">
                                <div className="relative flex flex-col gap-1">
                                    <label className="font-medium text-[13px]">
                                        Subdivision Name (Interface) <span className="text-sm text-red-600">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="subdivisionName"
                                        autoComplete="off"
                                        className="w-full px-2 py-[5px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none"
                                        placeholder="Search Subdivision & Select from list"
                                        value={subDivisionSearchQuery}
                                        onChange={handleSubDivisionSearch}
                                    />
                                    {/* Search Division */}
                                    {subDivisionList.length > 0 &&
                                        <div className="absolute w-full rounded-md gap-1 flex flex-col border p-2 top-16 z-10  bg-white shadow-lg">
                                            <div className="flex flex-col gap-1 overflow-y-scroll max-h-56">
                                                {subDivisionList.map((data) => (
                                                    <div className="flex justify-between items-center border px-2 rounded-md bg-blue-50 mt-1 hover:bg-blue-100 cursor-pointer">
                                                        <div className="flex flex-col gap-1 px-1 py-1 rounded-md">
                                                            <div className="text-[11px] font-medium py-2">{data.company_name} / {data.circle_name} / {data.division_name} / <span className="font-semibold text-sm text-orange-600">{data.subdivision_name}</span></div>
                                                        </div>
                                                        <div className="rounded-md font-medium text-[12.5px] bg-orange-600 text-white px-2 py-1 hover:bg-orange-500" onClick={() => { handleSelectedSubDivision(data.subdivision_name, data.eurja_code) }}>Select</div>
                                                    </div>
                                                ))
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-span-2">
                                <div className="flex flex-col gap-1 w-full">
                                    <label className="font-medium text-[13px]">Manage By<span className="text-sm text-red-600">*</span></label>
                                    <select type="text" id="manageBy" name="manageBy" className="w-full px-2 py-[6px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none" value={formData.manageBy} onChange={handleInputChange}>
                                        <option value="">Select</option>
                                        <option value="Getco">GETCO</option>
                                        <option value="Outsource">OUTSOURCE</option>
                                        <option value="Outsource">EHT</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-span-2">
                                <div className="flex flex-col gap-1 w-full">
                                    <label className="font-medium text-[13px]">Monitoring <span className="text-sm text-red-600">*</span></label>
                                    <select type="text" id="monitoring" name="monitoring" className="w-full px-2 py-[6px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none" value={formData.monitoring} onChange={handleInputChange}>
                                        <option value="">Select</option>
                                        <option value="Manual">Manual</option>
                                        <option value="SFMS">SFMS</option>
                                        <option value="SCADA">SCADA</option>
                                        <option value="Private">Private</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* Third row grid */}
                        <div className="grid grid-cols-12 gap-x-4 ">

                            <div className="col-span-2">
                                <div className="flex flex-col gap-1 w-full">
                                    <label className="font-medium text-[13px]">Charging Date <span className="text-sm text-red-600">*</span></label>
                                    <input type="date" id="chargingDate" name="chargingDate" className="w-full px-2 py-[4px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none" value={formData.chargingDate} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="flex flex-col gap-1 w-full">
                                    <label className="font-medium text-[13px]">Coastal <span className="text-sm text-red-600">*</span></label>
                                    <select type="text" id="coastal" name="coastal" className="w-full px-2 py-[6px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none" value={formData.coastal} onChange={handleInputChange}>
                                        <option value="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="flex flex-col gap-1 w-full">
                                    <label className="font-medium text-[13px]">Tribal <span className="text-sm text-red-600">*</span></label>
                                    <select type="text" id="tribal" name="tribal" className="w-full px-2 py-[6px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none" value={formData.tribal} onChange={handleInputChange}>
                                        <option value="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-span-2">
                                <div className="flex flex-col gap-1 w-full">
                                    <label className="font-medium text-[13px]">SS Category <span className="text-sm text-red-600">*</span></label>
                                    <select type="text" id="ssCategory" name="ssCategory" className="w-full px-2 py-[6px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none" value={formData.ssCategory} onChange={handleInputChange}>
                                        <option value="">Select</option>
                                        <option value="AIS">AIS</option>
                                        <option value="GIS">GIS</option>
                                        <option value="HGIS">HGIS</option>
                                        <option value="GIS+AIS">GIS+AIS</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <div className="flex flex-col gap-1 w-full">
                                    <label className="font-medium text-[13px]">Email<span className="text-sm text-red-600">*</span></label>
                                    <input type="email" id="emailAddress" name="emailAddress" className="w-full px-2 py-[5px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none" value={formData.emailAddress} onChange={handleInputChange} autoComplete="off" />
                                </div>
                            </div>
                            <div className="col-span-2">
                                <div className="flex flex-col gap-1 w-full">
                                    <label className="font-medium text-[13px]">Mobile No <span className="text-sm text-red-600">*</span></label>
                                    <input type="text" id="mobileNo" name="mobileNo" maxLength="10" className="w-full px-2 py-[5px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none" value={formData.mobileNo} onChange={handleInputChange} autoComplete="off" />
                                </div>
                            </div>
                            {/* <div className="col-span-2">
                                <div className="flex flex-col gap-1 w-full">
                                    <label className="font-medium text-[13px]">
                                        Sub Division TnD Code
                                    </label>
                                    <input type="text" id="sdnTnDCode" name="sdnTnDCode" autoComplete="off" className="w-full px-2 py-[5px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none" value={formData.sdnTnDCode} onChange={handleInputChange} />
                                </div>
                            </div> */}
                        </div>

                    </div>
                    {/* End Body */}

                    {/* Start Footer */}
                    <div className="bg-gray-100">
                        <div className="flex justify-end px-6 py-2 gap-2">
                            <div className="bg-gray-700 px-3 py-2 text-[0.8125rem] font-semibold leading-5 text-white rounded-md text-center shadow-md hover:bg-gray-700 cursor-pointer hover:shadow-xl">
                                Cancel
                            </div>
                            <div className="bg-orange-600 px-3 py-2 text-[0.8125rem] font-semibold leading-5 text-white rounded-md text-center shadow-md hover:shadow-xl cursor-pointer" onClick={handleSubmit}>Submit</div>
                        </div>
                    </div>
                    {/* End Footer */}
                </div>
            </div >
        </>
    );

};
export default EditSubstation;
