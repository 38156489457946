import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { string, z } from 'zod'
import Alert from './Alert';
import { getReasonList } from '../utils/utils';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import AuthContext from '../store/AuthContext';



const AddOutage = () => {
    const [reasonList, setReasonList] = useState([]);
    const [selectedReason, setSelectedReason] = useState([])
    const [visibleReasonList, setVisibleReasonList] = useState(false)
    const [message, setMessage] = useState();
    const [visiblePopup, setVisiblePopup] = useState(false);
    const handleSelectedReason = (e) => {
        const checkboxId = e.target.value;
        if (e.target.checked) {
            // If the checkbox is checked, add the reason to selectedReason
            setSelectedReason([...selectedReason, { id: checkboxId, name: e.target.labels[0].textContent }]);
        } else {
            // If the checkbox is unchecked, remove the reason from selectedReason
            setSelectedReason(prevSelectedReasons =>
                prevSelectedReasons.filter(reason => reason.id !== checkboxId)
            );
        }

    }
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();

    //Only for Substation User otherwise redirect to '/' Homepage .
    // if (!user || user?.companyName != 'GETCO' || user?.orgType != 'substation') {
    //     navigate('/')
    // }

    const apiUrl = process.env.REACT_APP_API_URL;
    const [feederList, setFeederList] = useState([]);
    const [isError, setIsError] = useState(null)
    const [formData, setFormData] = useState({
        outageType: '',
        feederId: '',
        isAdom: false,
        outageStartDate: moment().format('YYYY-MM-DD'),
        outageStartTime: '',
        outageEndDate: '',
        outageEndTime: '',
        supplyMode: '',
        relayFlag: '',
        brokenConductor: '',
        phase: '',
        faultCurrentR: '',
        faultCurrentY: '',
        faultCurrentB: '',
        faultCurrentG: '',
        faultCurrentN: '',
        shutdownBy: '',
        discomShutdownEmpNo: '',
        otherName: '',
        otherMobile: '',
        lcTaken: '',
        lcNo: '',
        discomLcEmpNo: '',
        reason: [],
    });
    const outageTypeList = [
        { name: "Trial TT", value: '2' },
        { name: "Normal TT", value: '3' },
        { name: "Sustain Fault (SF)", value: '4' },
        { name: "Emergency Shutdown (ESD)", value: '5' },
        { name: "Planned Shutdown (PSD)", value: '6' },
        { name: "AG Feeder Changeover", value: '7' }
    ]
    const relayFlagList = [
        { name: 'Over Current', value: 'Over Current' },
        { name: 'Earth Fault', value: 'Earth Fault' },
        { name: 'Over Current & Earth Fault', value: 'Over Current & Earth Fault' },
        { name: 'Over Current Highset', value: 'Over Current Highset' },
        { name: 'Earth Fault Highset', value: 'Earth Fault Highset' },
        { name: 'Over Current Highset & Earth Fault Highset', value: 'Over Current Highset & Earth Fault Highset' }
    ]
    useEffect(() => {
        if (formData.outageType) {
            getReasonList(formData.outageType).then(data => setReasonList(data.detail))
        }
    }, [formData.outageType])

    useEffect(() => {
        setSelectedReason([]);
        setVisibleReasonList(false)
        setFormData({ ...formData, reason: [] })
    }, [reasonList])

    useEffect(() => {
        setFormData({ ...formData, reason: selectedReason })
    }, [selectedReason])

    const validationSchema = z.object({
        outageType: z.string({ required_error: 'Please Select Outage Type' })
            .trim()
            .min(1, { message: 'Outage type required' }),

        feederId: z.string({ required_error: 'Please Select Feeder' })
            .trim()
            .min(1, { message: 'Feeder ID required' }),

        outageStartDate: z.string({ required_error: 'Outage Start Date is required' })
            .trim()
            .refine(data => !isNaN(Date.parse(data)), { message: 'Invalid start date format, please use a valid date.' }),

        outageStartTime: z.string()
            .trim()
            .refine(data => {
                const twentyFourHourRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
                const twelveHourRegex = /^(0[1-9]|1[0-2]):([0-5]\d) (AM|PM)$/i;
                return twentyFourHourRegex.test(data) || twelveHourRegex.test(data);
            }, { message: 'Invalid start time format, please use a valid time.' })
            .transform(data => {
                if (/^([01]\d|2[0-3]):([0-5]\d)$/.test(data)) {
                    return data;  // Already in 24-hour format
                } else {
                    let [time, modifier] = data.split(' ');
                    let [hours, minutes] = time.split(':');
                    if (modifier.toLowerCase() === 'pm' && hours !== '12') {
                        hours = String(parseInt(hours, 10) + 12);  // Convert PM hour to 24-hour format
                    } else if (modifier.toLowerCase() === 'am' && hours === '12') {
                        hours = '00';  // Midnight case
                    }
                    return `${hours}:${minutes}`;
                }
            }),

        outageEndDate: z.string().trim(),

        outageEndTime: z.string().trim(),

        supplyMode: z.string().trim(),

        relayFlag: z.string().optional(),

        brokenConductor: z.string().optional(),

        phase: z.string().optional(),

        faultCurrentR: z.string().optional(),
        faultCurrentY: z.string().optional(),
        faultCurrentB: z.string().optional(),
        faultCurrentG: z.string().optional(),
        faultCurrentN: z.string().optional(),

        shutdownBy: z.string().optional(),
        discomShutdownEmpNo: z.string().optional(),
        otherName: z.string().optional(),
        otherMobile: z.string().optional().refine((data) => {
            return data === undefined || data.trim() === '' || /^[0-9]{10}$/.test(data);
        }, {
            message: "other's Mobile must be blank or a 10-digit number."
        }),
        lcTaken: z.string().optional(),
        lcNo: z.string().optional(),
        discomLcEmpNo: z.string().optional(),
        reason: z.array().optional()
    }).refine(data => {
        // Validate End Date based on Outage Type
        if (data.outageType !== "2" && data.outageEndDate !== undefined && isNaN(Date.parse(data.outageEndDate))) {
            return false; // Invalid date format
        } else if (data.outageType === '2' && (data.outageEndTime.trim() === '' || data.outageEndDate.trim() === '')) {
            return false; // End time or date cannot be empty for type 2
        }
        return true;
    }, {
        message: 'Please ensure valid end date/time entries as per outage type.'
    }).refine(data => {
        // Validate Outage End Time again based on the Outage Type
        const twentyFourHourRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        const twelveHourRegex = /^(0[1-9]|1[0-2]):([0-5]\d) (AM|PM)$/i;
        if (data.outageType !== "2" && !twentyFourHourRegex.test(data.outageEndTime) && !twelveHourRegex.test(data.outageEndTime)) {
            return false;  // End time is invalid
        }
        return true;
    }, {
        message: 'Invalid end time format, please use a valid time.'
    }).refine(data => {
        // Additional condition for ADOM
        if (formData.isAdom) {
            return data.supplyMode.trim() !== '';  // Ensure supply mode is not empty
        }
        return true;
    }, {
        message: 'Select a Supply Mode'
    }).refine(data => {
        if (data.outageType == '2' || data.outageType == '3' || data.outageType == '4') {
            return !(data.relayFlag == null || data.relayFlag === '')
        }
        return true;
    }, {
        message: 'Relay Flag Require'

    }).refine(data => {
        if (data.outageType == '2' || data.outageType == '3' || data.outageType == '4') {
            return !(data.brokenConductor == null || data.brokenConductor === '')
        }
        return true;
    }, {
        message: 'Broken Conductor Require'

    }).refine(data => {
        if (data.outageType == '2' || data.outageType == '3' || data.outageType == '4') {
            return !(data.phase == null || data.phase === '')
        }
        return true;
    }, {
        message: 'Phase Require'

    }).refine((data) => {
        if (data.outageType === '2' || data.outageType === '3' || data.outageType === '4') {
            return !(data.faultCurrentR < 0 || (isNaN(data.faultCurrentR) && data.faultCurrentR?.length > 0))
        }
        return true
    },
        {
            message: 'R Fault Current must be positive number'
        }
    ).refine((data) => {
        if (data.outageType === '2' || data.outageType === '3' || data.outageType === '4') {
            return !(data.faultCurrentY < 0 || (isNaN(data.faultCurrentY) && data.faultCurrentY?.length > 0))
        }
        return true
    },
        {
            message: 'Y Fault Current must be positive number'
        }).refine((data) => {
            if (data.outageType === '2' || data.outageType === '3' || data.outageType === '4') {
                return !(data.faultCurrentB < 0 || (isNaN(data.faultCurrentB) && data.faultCurrentB?.length > 0))
            }
            return true
        },
            {
                message: 'B Fault Current must be positive number'
            }
        ).refine((data) => {
            if (data.outageType === '2' || data.outageType === '3' || data.outageType === '4') {
                return !(data.faultCurrentG < 0 || (isNaN(data.faultCurrentG) && data.faultCurrentG?.length > 0))
            }
            return true
        },
            {
                message: 'G Fault Current must be positive number'
            }
        ).refine((data) => {
            if (data.outageType === '2' || data.outageType === '3' || data.outageType === '4') {
                return !(data.faultCurrentN < 0 || (isNaN(data.faultCurrentN) && data.faultCurrentN?.length > 0))
            }
            return true
        },
            {
                message: 'N Fault Current must be positive number'
            }
        ).refine((data) => {
            if (data.outageType === '5') {
                return !(data.shutdownBy == null || data.shutdownBy == '')
            }
            return true
        },
            {
                message: 'Shutdown By Require'
            }
        ).refine((data) => {
            if (data.outageType === '5' && data.shutdownBy == 'employee') {
                return !(data.discomShutdownEmpNo == null || data.discomShutdownEmpNo == '' || isNaN(data.discomShutdownEmpNo))
            }
            return true
        },
            {
                message: 'Discom Emp No (Shutdown) Require'
            }
        ).refine((data) => {
            if (data.outageType === '5' && data.shutdownBy == 'employee') {
                return !(data.discomShutdownEmpNo == null || data.discomShutdownEmpNo == '' || isNaN(data.discomShutdownEmpNo))
            }
            return true
        },
            {
                message: 'Discom Emp No (Shutdown) Require'
            }
        ).refine((data) => {
            if ((data.outageType == '5' || data.outageType == '6') && data.shutdownBy == 'employee') {
                return !(data.lcTaken == null || data.lcTaken == undefined || data.lcTaken === '')
            }
            return true
        },
            {
                message: 'Select value of LC Taken'
            }
        ).refine((data) => {
            if (data.outageType == '5' || data.outageType == '6') {
                if (data.lcTaken == 'true') {

                    return !(data.lcNo == null || data.lcNo == undefined || data.lcNo === '' || data.discomLcEmpNo == null || data.discomLcEmpNo == undefined || data.discomLcEmpNo === '' || isNaN(data.discomLcEmpNo))
                }
            }
            return true
        },
            {
                message: 'LC No. & Discom Employee No. are require'
            }
        ).refine((data) => {

            if (data.outageType == '6') {

                return !(formData.reason.length == 0);
            }
            return true
        }, {
            message: 'Please Select Reason'
        })

    const handleSubmit = async () => {

        setFormData({ ...formData, reason: selectedReason })
        // const result = validationSchema.safeParse(formData);

        // if (!result.success) {
        //     setMessage(result.error.issues[0].message)
        //     setVisiblePopup(true)
        // }
        // else {
        try {
            const response = await fetch(`${apiUrl}/outage/add`,
                {
                    method: 'POST',
                    body: JSON.stringify(formData),
                    headers: {
                        "Content-type": 'application/json; charset=UTF-8',
                        "authorization": "bearer " + localStorage.getItem('auth_token')
                    }
                });
            if (response.ok) {
                const responseData = await response.json();

            }
            else {
                const responseData = await response.json();
                setMessage(responseData.message);
                setVisiblePopup(true);
            }
        }
        catch (err) {
            console.log(err.message);
            setMessage(err.message);
            setVisiblePopup(true);
        }

        // }
    }



    const handleInputChange = (e) => {
        const { name, value } = e.target;


        let isAdom = formData.isAdom
        if (name == 'feederId') {

            const selectedIndex = e.target.selectedIndex; // Get index of the selected option
            const selectedOption = e.target.options[selectedIndex]; // Access the selected option

            // Access custom data attributes of the selected option
            const dataCategory = selectedOption.getAttribute('data-category');
            isAdom = dataCategory == 'ADOM' || dataCategory == 'AGSKY' ? true : false;
        }
        // const parsedValue = e.target.type === 'number' && !isNaN(parseFloat(value)) ? parseFloat(value) : value;
        setFormData({
            ...formData,
            [name]: value,
            isAdom: isAdom,
            reason: selectedReason
        })
    }

    useEffect(() => {
        async function getFeederList() {
            try {
                const response = await fetch(`${apiUrl}/feeder/getFeederBySSEurjaCode`,
                    {
                        method: 'GET',
                        headers: {
                            "Content-type": 'application/json; charset=UTF-8',
                            "authorization": "bearer " + localStorage.getItem('auth_token')
                        }
                    });
                if (response.ok) {
                    const data = await response.json();
                    if (data) {
                        setFeederList(data);
                    }
                }
            }
            catch (error) {
                setMessage(error.message)
            }
        }
        getFeederList()
    }, []);
    useEffect(() => { console.log(formData) }, [formData])
    return (
        <div className='bg-[#F8F8F8] min-h-screen p-2 md:p-5'>
            {visiblePopup && <Alert message={message} setVisiblePopup={setVisiblePopup} />}
            <div className='max-w-2xl mx-auto bg-white border shadow-md rounded-md'>
                {/* Start Header */}
                <div className='flex flex-col bg-gray-100 px-4 py-2'>
                    <span className='text-base font-semibold leading-7 text-gray-800'>Add Outage</span>
                </div>
                {/* End Header */}

                {/* Start Body */}
                <div className='w-full flex flex-col gap-1 md:gap-2.5 py-6 px-6'>
                    <div className='grid grid-cols-1 gap-1 md:gap-5 md:grid-cols-6'>
                        <div className={formData.isAdom ? 'md:col-span-2 col-span-3' : 'col-span-3'}>
                            <label className="font-medium text-[12px]">Outage Type</label>
                            <select className='w-full  px-2 py-2 rounded-md border border-gray-300 text-sm focus:border-gray-500 focus:outline-none' id="outageType" name="outageType" onChange={handleInputChange} value={formData.outageType} >
                                <option value=""></option>
                                {
                                    outageTypeList.map((e) => (<option value={e.value}>{e.name}</option>))
                                }
                            </select>
                        </div>
                        <div className='col-span-3'>
                            <label className="font-medium text-[12px]">Feeder</label>
                            <select className='w-full px-2 py-2 rounded-md border border-gray-300 text-sm focus:border-gray-500 focus:outline-none' id="feederId" name="feederId" onChange={handleInputChange} value={formData.feederId}>
                                <option value=""></option>
                                {feederList && feederList.map((e) =>
                                    (<option value={e.id} data-category={e.categor}>{e.feeder_name} ({e.categor})</option>)
                                )}
                            </select>
                        </div>
                        {
                            formData.isAdom && (
                                <div className='md:col-span-1 col-span-3'>
                                    <label className="font-medium text-[12px]">Supply Mode</label>
                                    <select className='w-full px-2 py-2 rounded-md border border-gray-300 text-sm focus:border-gray-500 focus:outline-none' id="supplyMode" name="supplyMode" onChange={handleInputChange} value={formData.supplyMode}>
                                        <option value=""></option>
                                        <option value="1PH">1PH</option>
                                        <option value="3PH">3PH</option>
                                    </select>
                                </div>
                            )
                        }
                    </div>

                    <div className='grid grid-cols-1 gap-1 md:gap-5 md:grid-cols-8'>
                        <div className='col-span-2'>
                            <label className="font-medium text-[12px]">Outage Start Date</label>
                            <input type="date" className='w-full px-2 py-[6px] rounded-md border border-gray-300 text-sm focus:border-gray-500 focus:outline-none' id="outageStartDate" name="outageStartDate" onChange={handleInputChange} value={formData.outageStartDate} />
                        </div>
                        <div className='col-span-2'>
                            <label className="font-medium text-[12px]">Outage Start Time</label>
                            <input type="time" className='w-full px-2 py-[6px] rounded-md border border-gray-300 text-sm focus:border-gray-500 focus:outline-none' id="outageStartTime" name="outageStartTime" onChange={handleInputChange} value={formData.outageStartTime} />
                        </div>
                        <div className='col-span-2'>
                            <label className="font-medium text-[12px]">Outage End Date</label>
                            <input type="date" className='w-full px-2 py-[6px] rounded-md border border-gray-300 text-sm focus:border-gray-500 focus:outline-none' id="outageEndDate" name="outageEndDate" onChange={handleInputChange} value={formData.outageEndDate} />
                        </div>
                        <div className='col-span-2'>
                            <label className="font-medium text-[12px]">Outage End Time</label>
                            <input type="time" className='w-full px-2 py-[6px] rounded-md border border-gray-300 text-sm focus:border-gray-500 focus:outline-none' id="outageEndTime" name="outageEndTime" onChange={handleInputChange} value={formData.outageEndTime} />
                        </div>
                    </div>
                    {["2", "3", "4"].includes(formData.outageType) &&

                        (<div className=''>
                            <div className='grid grid-cols-2 gap-1 md:gap-5 md:grid-cols-6'>
                                <div className='md:col-span-3 col-span-2'>
                                    <label className="font-medium text-[12px]">Relay Flag</label>
                                    <select className='w-full px-2 py-2 rounded-md border border-gray-300 text-sm focus:border-gray-500 focus:outline-none' id="relayFlag" name="relayFlag" onChange={handleInputChange} value={formData.relayFlag} >
                                        <option value=""></option>
                                        {
                                            relayFlagList.map((e) => (<option value={e.value}>{e.name}</option>))
                                        }
                                    </select>
                                </div>
                                <div className='md:col-span-2 col-span-1'>
                                    <label className="font-medium text-[12px]">Broken Conductor</label>
                                    <select className='w-full px-2 py-2 rounded-md border border-gray-300  text-sm focus:border-gray-500 focus:outline-none' id="brokenConductor" name="brokenConductor" onChange={handleInputChange} value={formData.brokenConductor}>
                                        <option value=""></option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className='md:col-span-1 col-span-1'>
                                    <label className="font-medium text-[12px]">Phase</label>
                                    <select className='w-full px-2 py-2 rounded-md border border-gray-300  text-sm focus:border-gray-500 focus:outline-none' id="phase" name="phase" onChange={handleInputChange} value={formData.phase}>
                                        <option value=""></option>
                                        <option value="R">R</option>
                                        <option value="Y">Y</option>
                                        <option value="B">B</option>
                                        <option value="RB">RB</option>
                                        <option value="YB">YB</option>
                                        <option value="RY">RY</option>
                                        <option value="RYB">RYB</option>
                                    </select>
                                </div>
                            </div>
                            <div className='mt-3 flex flex-wrap gap-4 items-center justify-start'>
                                <div className='flex flex-col gap-1'>
                                    <label className='block font-medium text-[12px]'>Fault Current</label>
                                    <div className='grid lg:grid-cols-5 lg:gap-x-1.5 gap-y-1 grid-cols-2 gap-x-2'>
                                        <div className="relative flex items-center border border-gray-300 rounded-md">
                                            <span className="absolute inset-y-0 left-0 flex items-center px-3 bg-gray-200 rounded-l-md ">
                                                <span className="block font-medium text-[12px] ">R</span>
                                            </span>
                                            <input type="text" className="pl-10 rounded-md py-[5px] px-1 outline-none w-full " id="faultCurrentR" name="faultCurrentR" onChange={handleInputChange} value={formData.faultCurrentR} />
                                        </div>
                                        <div class="relative flex items-center border border-gray-300 rounded-md">
                                            <span class="absolute inset-y-0 left-0 flex items-center px-3 bg-gray-200 rounded-l-md">
                                                <span class="block font-medium text-[12px]">Y</span>
                                            </span>
                                            <input type="text" className="pl-10 rounded-md py-[5px] px-1 outline-none w-full" id="faultCurrentY" name="faultCurrentY" onChange={handleInputChange} value={formData.faultCurrentY} />
                                        </div>
                                        <div class="relative flex items-center border-[1.5px] border-gray-300 rounded-md">
                                            <span class="absolute inset-y-0 left-0 flex items-center px-3 bg-gray-200 rounded-l-md">
                                                <span class="block font-medium text-[12px]">B</span>
                                            </span>
                                            <input type="text" className="pl-10 rounded-md py-[5px] px-1 outline-none w-full" id="faultCurrentB" name="faultCurrentB" onChange={handleInputChange} value={formData.faultCurrentB} />
                                        </div>
                                        <div class="relative flex items-center border border-gray-300 rounded-md">
                                            <span class="absolute inset-y-0 left-0 flex items-center px-3 bg-gray-200 rounded-l-md">
                                                <span class="block font-medium text-[12px]">G</span>
                                            </span>
                                            <input type="text" className="pl-10 rounded-md py-[5px] px-1 outline-none w-full" id="faultCurrentG" name="faultCurrentG" onChange={handleInputChange} value={formData.faultCurrentG} />
                                        </div>
                                        <div class="relative flex items-center border border-gray-300 rounded-md">
                                            <span class="absolute inset-y-0 left-0 flex items-center px-3 bg-gray-200 rounded-l-md">
                                                <span class="block font-medium text-[12px]">N</span>
                                            </span>
                                            <input type="text" className="pl-10 rounded-md py-[5px] px-1 outline-none w-full" id="faultCurrentN" name="faultCurrentN" onChange={handleInputChange} value={formData.faultCurrentN} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>)
                    }
                    {["5"].includes(formData.outageType) &&
                        (<>

                            <div className='grid grid-cols-2 gap-1 md:gap-5 md:grid-cols-7'>
                                <div className='col-span-3'>
                                    <label className="font-medium text-[12px]">Shutdown By</label>
                                    <select className='w-full px-2 py-2 rounded-md border border-gray-300  text-sm focus:border-gray-500 focus:outline-none' id="shutdownBy" name="shutdownBy" onChange={handleInputChange} value={formData.shutdownBy}>
                                        <option value="">Select</option>
                                        <option value="employee">Discom Employee</option>
                                        <option value="getco_employee">Getco Employee</option>
                                        <option value="other_person">Other Person</option>
                                    </select>
                                </div>
                                {formData.shutdownBy == 'employee' &&
                                    (<div className='col-span-4'>
                                        <label className="font-medium text-[12px]">Discom Emp No (Shutdown)</label>
                                        <input type="text" className='w-full px-2 py-[5px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none' id="discomShutdownEmpNo" name="discomShutdownEmpNo" onChange={handleInputChange} value={formData.discomShutdownEmpNo} />
                                    </div>)
                                }
                                {formData.shutdownBy == 'other_person' &&
                                    (<>
                                        <div className='col-span-2'>
                                            <label className="font-medium text-[12px]">Other's Name</label>
                                            <input type="text" className='w-full px-2 py-[5px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none' id='otherName' name='otherName' onChange={handleInputChange} value={formData.otherName} />
                                        </div>
                                        <div className='col-span-2'>
                                            <label className="font-medium text-[12px]">Other's Mob. No</label>
                                            <input type="number" max="10" className='w-full px-2 py-[5px] rounded-md border border-gray-300 focus:border-gray-500 focus:outline-none' id='otherMobile' name='otherMobile' onChange={handleInputChange} value={formData.otherMobile} />
                                        </div>
                                    </>)}
                            </div>
                        </>)}
                    {["5", "6"].includes(formData.outageType) &&
                        (<>
                            {(formData.shutdownBy == 'employee' || formData.outageType == '6') &&
                                (
                                    <div className='grid grid-cols-2 gap-1 md:gap-5 md:grid-cols-7'>
                                        <div className='col-span-3'>
                                            <label className="font-medium text-[12px]">LC Taken</label>
                                            <select className='w-full px-2 py-2 rounded-md border border-gray-300  text-sm focus:border-gray-500 focus:outline-none' id="lcTaken" name="lcTaken" onChange={handleInputChange} value={formData.lcTaken}  >
                                                <option value=""> </option>
                                                <option value="true" >Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                        {(formData.lcTaken == "true") &&
                                            (<>
                                                <div className='col-span-2'>
                                                    <label className="font-medium text-[12px]">LC No</label>
                                                    <input type="number" min="1" className='w-full px-2 py-[7px] rounded-md border border-gray-300 text-sm focus:border-gray-500 focus:outline-none' id="lcNo" name="lcNo" onChange={handleInputChange} value={formData.lcNo} />
                                                </div>
                                                <div className='col-span-2'>
                                                    <label className="font-medium text-[12px]">Discom Emp No (LC)</label>
                                                    <input type="number" min="1" className='w-full px-2 py-[7px] rounded-md border border-gray-300 text-sm focus:border-gray-500 focus:outline-none' id="discomLcEmpNo" name="discomLcEmpNo" onChange={handleInputChange} value={formData.discomLcEmpNo} />
                                                </div>
                                            </>)
                                        }
                                    </div>
                                )
                            }
                        </>)
                    }
                    {/* Reason section */}
                    {(formData.outageType == "5" || formData.outageType == "6") && <div className="relative text-left z-20 pt-1">

                        <div className="font-medium text-[12px]">Reason</div>
                        <span className="rounded-md shadow-sm" onClick={() => setVisibleReasonList(!visibleReasonList)}>
                            <div className="inline-flex justify-between w-full items-center rounded-md  border border-gray-300 px-2 py-3 bg-white text-sm font-medium leading-5  text-gray-500 hover:text-gray-500 focus:outline-none focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
                                <span>{selectedReason.length > 0 && <>
                                    <div className="flex flex-wrap items-center  rounded-md ">
                                        {/* <div className="text-xs">Clear</div> */}
                                        {
                                            selectedReason.map((item) => {
                                                return (<label className="">
                                                    <div className="text-xs  bg-gray-200  text-gray-800 border shadow-sm rounded-md font-medium px-2 mx-0.5 my-0.5 py-0.5">{item.name}</div>
                                                </label>)
                                            })
                                        }

                                    </div>
                                </>} </span>
                                <span className="flex">{visibleReasonList ? <CaretUpFill /> : <CaretDownFill />}</span>
                            </div>
                        </span>
                        {visibleReasonList &&
                            <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg ">
                                <div className="rounded-md bg-white shadow-lg border max-h-52 overflow-x-scroll">
                                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">


                                        {/* {reason.length > 0 && <div className="text-xs  text-orange-600 mx-1 sticky">Clear</div>} */}
                                        {reasonList.map((item) => {
                                            return (<span className="flex items-center space-x-2 px-4 py-1  hover:text-orange-600 w-full hover:bg-gray-100 " key={item.id}>
                                                <input
                                                    type="checkbox"
                                                    className="form-checkbox h-4 w-4 text-orange-500 hover:cursor-pointer"
                                                    value={item.id}
                                                    name={item.id}
                                                    id={item.id}
                                                    onChange={handleSelectedReason}
                                                    label={item.name}
                                                    checked={selectedReason.some(x => x.id == item.id)}
                                                />
                                                <label className=" cursor-pointer text-xs font-medium" htmlFor={item.id}>{item.name}</label>
                                            </span>)
                                        })}

                                    </div>
                                </div>
                            </div>
                        }


                    </div>}

                </div>
                {/* End Body */}

                {/* Start Footer */}
                <div className='bg-gray-100'>
                    <div className='flex justify-end px-6 py-2 gap-2'>
                        <div className="bg-gray-700 px-3 py-2 text-[0.8125rem] font-semibold leading-5 text-white rounded-md text-center shadow-md hover:bg-gray-700 cursor-pointer hover:shadow-xl">Cancel</div>
                        <div className="bg-orange-600 px-3 py-2 text-[0.8125rem] font-semibold leading-5 text-white rounded-md text-center shadow-md hover:shadow-xl cursor-pointer" onClick={handleSubmit}>Submit</div>
                    </div>
                </div>
                {/* End Footer */}
            </div>
        </div >
    )
}

export default AddOutage