import React, { useState } from "react";
import { Link } from "react-router-dom";
import { login } from "../utils/appSlice";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../store/AuthContext"

const Header = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [isVisibleProfile, setIsVisibleProfile] = useState(false);
    const [isVisibleBurgerMenu, setIsVisibleBurgerMenu] = useState(false);

    const authCtx = useContext(AuthContext);

    const navigate = useNavigate();

    const handleLogout = async (req, res) => {
        try {
            const response = await fetch(`${apiUrl}/auth/logout`,
                {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "authorization": 'bearer ' + localStorage.getItem('auth_token')
                    }
                });
            authCtx.logout();
            navigate('/auth/login');
        }
        catch (error) {
            res.json(401).json({ message: 'Something wrong to logout' })
        }

    }
    const getSurfaceToken = async (req, res) => {
        const response = await fetch(`${apiUrl}/api/surface_token`, {
            method: 'GET',
            headers: {
                'Authorization': "bearer " + localStorage.getItem('auth_token'),
                'Content-Type': 'application/json'
            }
        });
        const responseData = await response.json()
        if (responseData.ticket) {
            window.open("https://surface.geourja.com/trusted/" + responseData.ticket + "/views/Power_Off/Dashboard1?:iid=8", "_blank");
        }
    }
    return (
        <>
            <div className=" bg-white px-8  md:px-12 shadow-md h-14 border-gray-200 border flex justify-between items-center font-inter">
                <div className="flex items-center">
                    <img
                        alt="Geourja"
                        src="/img/geourja.png"
                        className="h-9 pr-2  border-r border-gray-300"
                    />

                    <p className=" pr-1 pl-2 text-[36px] font-bold bg-clip-text bg-gradient-to-r from-sky-800 via-orange-700 to-orange-500 text-transparent ">
                        OMS
                    </p>
                    <div className="flex flex-col">
                        <span className="text-gray-800 text-[8px] font-bold">
                            OUTAGE
                        </span>
                        <span className="text-gray-800 text-[8px] font-bold">
                            MANAGEMENT
                        </span>
                        <span className="text-gray-800 text-[8px] font-bold">
                            SYSTEM
                        </span>
                    </div>
                </div>
                <ul className="md:flex space-x-7  font-semibold hidden ">
                    <li className="text-[14px] font-semibold text-gray-800 cursor-pointer">
                        <Link to="/">Home</Link>
                    </li>
                    <li className="text-[14px] font-semibold text-gray-800 cursor-pointer">
                        <Link to='/summary'> Summary</Link>
                    </li>
                    <li className="text-[14px] font-semibold text-gray-800 cursor-pointer">
                        <Link onClick={getSurfaceToken}> Surface</Link>
                    </li>
                    <li className="text-[14px] font-semibold text-gray-800 cursor-pointer">
                        <Link to="/outages">Outages</Link>
                    </li>
                    {authCtx.user.permissions.find((e) => e === 'manage-substation' || e === 'super admin' || e === 'admin') && (
                        <li className="text-[14px] font-semibold text-gray-800 cursor-pointer">
                            <Link to="/modules">Modules</Link>
                        </li>
                    )}

                    {/* <li className="text-[14px] font-semibold text-gray-800 cursor-pointer">
                        <Link to="/reports">Reports</Link>
                    </li>
                    <li className="text-[14px] font-semibold text-gray-800 cursor-pointer">
                        Help
                    </li> */}

                    {authCtx.user ? (

                        <li className=" text-[14px] font-semibold text-gray-800 cursor-pointer" onClick={() => { setIsVisibleProfile(!isVisibleProfile) }} >
                            <div className="flex items-center">
                                <div className="mr-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-person-circle  " viewBox="0 0 16 16">
                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                        <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                    </svg>
                                </div>

                                {isVisibleProfile ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="text-orange-600 bi bi-caret-up-fill" viewBox="0 0 16 16">
                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                    </svg>
                                ) : (
                                    <div>
                                        < svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className=" text-orange-600 bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                )
                                }
                            </div>
                            {isVisibleProfile ? (
                                <div className="fixed w-80 -ml-64 mt-6 bg-white rounded-md shadow-md p-3 animate-fade-in-up z-10">
                                    <ul>
                                        {console.log(authCtx.user)}
                                        <li className="  my-1 truncate ..."><span className="text-orange-600">Name : </span><span className='capitalize'>{authCtx.user.name}</span></li>
                                        <li className="my-1"><span className="text-orange-600">Emp No : </span><span>{authCtx.user.empNo}</span></li>
                                        <li className="my-1"><span className="text-orange-600">Company : </span><span>{authCtx.user.companyName}</span></li>
                                        <hr className="mt-2"></hr>
                                        <li onClick={handleLogout} className="flex pt-2">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="text-orange-600 bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                                                    <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                                                </svg>
                                            </div>
                                            <div className="ml-2 hover:text-orange-600">Logout</div>
                                        </li>
                                    </ul>
                                </div>
                            ) : null
                            }

                        </li >
                    ) : null}
                </ul >
                <div className="md:hidden  text-[25px] font-bold text-orange-600 cursor-pointer">
                    {
                        isVisibleBurgerMenu ? (<div className='text-gray-500' onClick={() => setIsVisibleBurgerMenu(!isVisibleBurgerMenu)}>&#9932;</div>) : <div className="" onClick={() => setIsVisibleBurgerMenu(!isVisibleBurgerMenu)}>&#9776;</div>
                    }
                    {
                        isVisibleBurgerMenu ? (
                            <div className="absolute bg-white rounded-md shadow-md right-0 p-2 w-32 z-10">
                                <ul className="flex-col flex space-y-2 mt-2  font-semibold  ">
                                    <li className="text-[14px] font-semibold text-gray-800 cursor-pointer">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="text-[14px] font-semibold text-gray-800 cursor-pointer">
                                        <Link to='/summary'> Summary</Link>
                                    </li>
                                    <li className="text-[14px] font-semibold text-gray-800 cursor-pointer">
                                        <Link onClick={getSurfaceToken}> Surface</Link>
                                    </li>
                                    <li className="text-[14px] font-semibold text-gray-800 cursor-pointer">
                                        <Link to="/outages">Outages</Link>
                                    </li>
                                    <li className="text-[14px] font-semibold text-gray-800 cursor-pointer">
                                        <Link to="/reports">Reports</Link>
                                    </li>
                                    {authCtx.user.permission.find((e) => e === 'manage-substation' || e === 'super admin' || e === 'admin') && (
                                        <li className="text-[14px] font-semibold text-gray-800 cursor-pointer">
                                            <Link to="/modules">Modules</Link>
                                        </li>)
                                    }
                                    <li onClick={handleLogout} className="flex pt-2 ">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="text-orange-600 bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                                                <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                                            </svg>
                                        </div>
                                        <div className="ml-2 hover:text-orange-600 text-[14px]">Logout</div>
                                    </li>
                                </ul>
                            </div>) : null
                    }
                </div>
            </div >
        </>
    );
}

export default Header;
