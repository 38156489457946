import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from 'uuid'; // For generating the clientId

const SOCKET_URL = "http://localhost:6002";  // Replace with your server URL

// Initialize the socket connection
const socket = io(SOCKET_URL, {
    extraHeaders: {
        authorization: localStorage.getItem("auth_token"), // Replace with actual token
    },
    reconnectionAttempts: 10,  // Retry up to 25 times before failing
    timeout: 2000,  // Timeout after 2 seconds if unable to connect
    autoConnect: false,  // Prevent socket from automatically connecting on page load
});

const SocketComponent = () => {
    const [pendingCount, setPendingCount] = useState(null);
    const [isConnected, setIsConnected] = useState(false);

    const clientId = uuidv4(); // Generate a unique clientId
    const organizationCode = localStorage.getItem("auth_token"); // State for organization code

    const topic = 'summary'; // The topic you're subscribing to

    useEffect(() => {
        // Connect to the socket server when the component is mounted
        socket.connect();

        // When the socket connects or reconnects, resubscribe to the topic
        socket.on('connect', () => {
            console.log('Connected to the socket server');
            setIsConnected(true);

            // Emit the subscribe event after connecting
            socket.emit('subscribe', topic);
            console.log(`Subscribed to topic: ${topic}`);
        });

        // Listen for the 'message' event to receive pending count updates
        socket.on('message', (data) => {
            console.log('Received data:', data);
            if (data.pendingCnt !== undefined) {
                setPendingCount(data.pendingCnt);
            }
        });

        // Handle socket disconnection
        socket.on('disconnect', () => {
            console.log('Disconnected from the socket server');
            setIsConnected(false);
        });

        // Cleanup: Disconnect the socket when the component is unmounted
        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <div>
            <h1>Socket.io Client with Authorization Header</h1>
            <div>
                <h2>Status: {isConnected ? "Connected" : "Disconnected"}</h2>
                <h2>Pending Reason:</h2>
                <p>{pendingCount !== null ? pendingCount : "No data available"}</p>
            </div>
        </div>
    );
};

export default SocketComponent;
