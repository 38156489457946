const sendHttpRequest = (webservice, authToken, params) => {
    console.log(params)
    const urlParams = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
    const url = `${webservice}?${urlParams}`;

    return fetch(url, {
        method: 'GET',
        headers: {
            authorization: `bearer ${authToken}`
        }
    })
        .then(response => {
            return response;
        })
        .catch(error => {
            // Handle the error, you can console.log it or return an object with an error message.
            console.log(error);
            return { 'status': error, 'Message': error.message };
        });
}

export default sendHttpRequest;

