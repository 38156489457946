import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowDownUp, Download, Eye, Funnel, FunnelFill, SortAlphaDown, SortAlphaUp, ChevronLeft, ChevronRight, ChevronDoubleLeft, ChevronDoubleRight, ArrowClockwise, Bug, Plus, Search, Ticket, X, XCircleFill, XLg } from "react-bootstrap-icons";
import sendHttpRequest from '../utils/sendHttpRequest'
import GridShimmer from "./GridShimmer";

import Loader from "./Loader";
import ErrorHandle from "./ErrorHandle";
import moment from "moment"
import LinkButton from "./LinkButton";
import { prepareAutoBatched } from "@reduxjs/toolkit";
const apiUrl = process.env.REACT_APP_API_URL;

function GridColumnVisibilityModal(props) {

    const [columns, setColumns] = useState([...props.columns]);

    const onChange = (idx) => {
        const _targetColumns = [...columns];
        _targetColumns[idx].visible = !_targetColumns[idx].visible;
        setColumns(_targetColumns);
    };

    const apply = () => {
        props.onSave(columns);
    };

    return (
        <>
            <div className="absolute left-0 top-0  w-full flex justify-center  bg-black/50 h-screen z-10 rounded-md border">
                <div className="absolute w-[95%] md:w-1/4 top-10  bg-white rounded-md border">
                    <div className="flex justify-between items-center h-12 bg-gray-100 px-3 py-2 border-b font-semibold">
                        <div className="flex items-center gap-1">
                            <Funnel />
                            <span>Column Visibility</span>
                        </div>
                        <XLg className="cursor-pointer" onClick={props.onHide} />
                    </div>
                    <div className="py-5 px-5 overflow-y-scroll h-80">
                        <div className="flex flex-col gap-x-2 justify-center gap-y-2">
                            {columns.map((ele, idx) => {
                                if (ele.visibilitySetting) {
                                    return (<div className="flex gap-y-2 gap-x-2 " key={idx}>
                                        <input
                                            type="checkbox"
                                            id={`grid-cb-${idx}`}
                                            checked={ele.visible}
                                            onChange={(e) => onChange(idx)} />
                                        <label className="text-sm cursor-pointer" htmlFor={ele.headerName}>{ele.headerName}</label>

                                    </div>)
                                }
                            })
                            }
                        </div>
                    </div>
                    <div className="flex h-12 justify-center py-2 gap-2 bg-gray-100 ">
                        <button className="px-5 py-1.5 bg-orange-500 rounded-md text-white font-medium text-sm hover:bg-orange-600" onClick={apply} >Apply</button>
                    </div>
                </div>
            </div>

        </>
    )
};

function GridFilter(props) {
    const [filterOperator, setFilterOperator] = useState(props.filterModal.column.filterOperator);
    const [filterText, setFilterText] = useState(props.filterModal.column.filterText);
    const [filterFromDate, setFilterFromDate] = useState(props.filterModal.column.filterFromDate);
    const [filterToDate, setFilterToDate] = useState(props.filterModal.column.filterToDate);

    const onFilterOperatorChange = (e) => {
        setFilterOperator(e.target.value)
    }
    const onFilterTextChange = (e) => {
        setFilterText(e.target.value)
    }
    const onFilterFromDateChange = (e) => {
        setFilterFromDate(e.target.value)
    }
    const onFilterToDateChange = (e) => {
        setFilterToDate(e.target.value)
    }
    const applyFilter = (event) => {
        event.preventDefault();
        const result = {
            columnId: props.filterModal.column.id,
            filterOperator: filterOperator,
            filterText: filterText.trim(),
            filterFromDate: filterFromDate,
            filterToDate: filterToDate
        };
        props.applyFilterHandler(result);
    };

    const clearFilter = (event) => {
        const result = {
            columnId: props.filterModal.column.id,
            filterOperator: '=',
            filterText: '',
            filterFromDate: '',//props.filterModal.column.filterFromDate,
            filterToDate: '',//props.filterModal.column.filterToDate
        };

        props.applyFilterHandler(result);
    };
    return (
        <>
            {props.filterModal.show && (
                <div className="absolute left-0 top-0 w-full flex justify-center  bg-black/50 h-screen z-10 rounded-md border">
                    <div className="absolute  top-24 w-[98%] md:w-1/2  bg-white rounded-md border">
                        <div className="flex justify-between items-center w-full bg-gray-100 px-3 py-2 border-b font-semibold">
                            <div className="flex items-center gap-1">
                                <Funnel />
                                <span>Filter</span>
                            </div>
                            <XLg className="cursor-pointer" onClick={props.hideFilter} />
                        </div>
                        <div className="py-10 px-5 w-full">
                            <div className="flex flex-col md:flex-row items-center justify-center gap-2">

                                <div>{props.filterModal.column && props.filterModal.column.headerName}</div>
                                {props.filterModal.column && props.filterModal.column.filterType == 'number' ? (
                                    <>

                                        <select className="py-2 border rounded-md w-40 text-center text-sm" value={filterOperator} onChange={onFilterOperatorChange}>
                                            <option value="=">{"="}</option>
                                            <option value="<=">{"<="}</option>
                                            <option value=">=">{">="}</option>
                                            <option value="!=">{"!="}</option>
                                            <option value="isEmpty">{"IS EMPTY"}</option>
                                        </select>
                                        <input type="text" className="py-2 border rounded-md w-56 px-2 text-sm" placeholder="Enter text" value={filterText} onChange={onFilterTextChange} />
                                    </>) : ''}

                                {props.filterModal.column && props.filterModal.column.filterType == 'text' ? (
                                    <>
                                        <select className="py-2 border rounded-md w-40 text-center text-sm" onChange={onFilterOperatorChange} value={filterOperator}>
                                            <option value="=">{"="}</option>
                                            <option value="startWith">{"START WITH"}</option>
                                            <option value="contain">{"CONTAIN"}</option>
                                            <option value="endWith">{"END WITH"}</option>
                                            <option value="isEmpty">{"IS EMPTY"}</option>
                                            <option value="notEqualTo">{"NOT EQUAL TO"}</option>
                                        </select>
                                        <input type="text" className="py-2 border rounded-md w-56 px-2 text-sm" placeholder="Enter text" value={filterText} onChange={onFilterTextChange} />
                                    </>) : ''}
                                {props.filterModal.column && props.filterModal.column.filterType == 'dateRange' ? (
                                    <>
                                        <div className="flex flex-col gap-2 md:flex-row">
                                            <div className="flex flex-col">
                                                <lable className="text-xs font-medium text-orange-500">From Date</lable>
                                                <input type="date" className="py-2 border rounded-md w-56 px-2 text-sm" placeholder="Enter text" onChange={onFilterFromDateChange} value={filterFromDate} />
                                            </div>
                                            <div className="flex flex-col">
                                                <lable className="text-xs  font-medium text-orange-500">To Date</lable>
                                                <input type="date" className="py-2 border rounded-md w-56 px-2 text-sm" placeholder="Enter text" onChange={onFilterToDateChange} value={filterToDate} />
                                            </div>
                                        </div>
                                    </>) : ''}

                            </div>
                        </div>
                        <div className="flex justify-center py-2 gap-x-2 bg-gray-100">
                            <button className="px-5 py-2 bg-black rounded-md text-white font-medium text-sm hover:bg-orange-600" onClick={clearFilter} >Clear</button>
                            <button className="px-5 py-2 bg-orange-500 rounded-md text-white font-medium text-sm hover:bg-orange-600" onClick={applyFilter} >Apply</button>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
}

function Pagination(props) {

    if (props.totalRecords > 0) {

        const lastPage = Math.ceil(props.totalRecords / props.recordsPerPage);
        return (
            <div className='flex justify-end  '>
                <button className='cursor-pointer rounded-sm  bg-gray-300 mr-1  w-7  h-7 min-w-fit p-1 flex items-center justify-center  text-xs font-semibold' disabled={props.currentPage === 1 ? true : false} onClick={() => props.changePageTo(1)}><ChevronDoubleLeft /></button>
                <button className='cursor-pointer rounded-sm  bg-gray-300 mr-1 w-7 min-w-fit p-1 h-7 flex items-center justify-center  text-xs font-semibold' disabled={props.currentPage === 1 ? true : false} onClick={() => props.changePageTo(props.currentPage - 1)}><ChevronLeft /></button>
                {props.currentPage >= 3 && <button className='cursor-pointer rounded-sm  bg-gray-300 mr-1 w-7 min-w-fit p-1 h-7 flex items-center justify-center text-xs font-semibold' onClick={() => props.changePageTo(props.currentPage - 2)}>{props.currentPage - 2}</button>}
                {props.currentPage >= 2 && <button className='cursor-pointer rounded-sm  bg-gray-300 mr-1 w-7 min-w-fit p-1 h-7 flex items-center justify-center  text-xs font-semibold' onClick={() => props.changePageTo(props.currentPage - 1)}>{props.currentPage - 1}</button>}
                <button className=' text-white cursor-pointer rounded-sm  bg-orange-600 mr-1 w-7 min-w-fit p-1 h-7 flex items-center justify-center  text-xs font-semibold'>{props.currentPage}</button>
                {(lastPage - props.currentPage) >= 1 && <button className='cursor-pointer rounded-sm  bg-gray-300 mr-1 w-7 min-w-fit p-1 h-7 flex items-center justify-center  text-xs font-semibold' onClick={() => props.changePageTo(props.currentPage + 1)} >{props.currentPage + 1}</button>}
                {(lastPage - props.currentPage) >= 2 && <button className='cursor-pointer rounded-sm  bg-gray-300 mr-1 w-7 min-w-fit p-1 h-7 flex items-center justify-center  text-xs font-semibold' onClick={() => props.changePageTo(props.currentPage + 2)}>{props.currentPage + 2}</button>}
                <button className=' cursor-pointer rounded-sm  bg-gray-300 mr-1 w-7 min-w-fit p-1 h-7 flex items-center justify-center  text-xs font-semibold' disabled={lastPage === props.currentPage ? true : false} onClick={() => props.changePageTo(props.currentPage + 1)}>< ChevronRight /></button>
                <button className='cursor-pointer rounded-sm  bg-gray-300 mr-1 w-7 min-w-fit p-1 h-7 flex items-center justify-center  text-xs font-semibold' disabled={lastPage === props.currentPage ? true : false} onClick={() => props.changePageTo(lastPage)} ><ChevronDoubleRight /></button>
            </div >
        )
    }
}

const Grid = (props) => {
    const [gridColumns, setGridColumns] = useState(props.columns.map((column, idx) => ({
        id: idx,
        allowSort: true,
        allowFilter: true,
        allowDownload: true,
        visibilitySetting: false,
        filterType: 'text',
        filterRangeValues: [],
        filterOperator: "=",
        filterText: '',
        filterFromDate: '',
        filterToDate: '',
        visible: true,
        visibilitySetting: true,
        renderer: null,
        ...column,
    })));

    const [isLoading, setIsLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [gridOrder, setGridOrder] = useState({
        orderBy: props.orderBy,
        orderByType: props.orderByType,
        defaultOrderBy: props.defaultOrderBy,
        defaultOrderByType: props.defaultOrderByType
    });
    const [gridRefCnt, setGridRefCnt] = useState(props.refreshCounter)
    const [gridTotalRecords, setGridTotalRecords] = useState();
    const [footerSummary, setFooterSummary] = useState();
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalRecords: 0,
        recordsPerPage: props.recordsPerPage
    });

    const [filterModal, setFilterModal] = useState({
        column: null,
        show: false,
    });
    const [showVisibilityModal, setShowVisibilityModal] = useState(false);
    const showVisibilitySetting = () => {
        setShowVisibilityModal(true);
    }

    const hideVisibilitySetting = () => {
        setShowVisibilityModal(false);
    }
    function changePageTo(pageNumber) {
        fetchData(pageNumber)
    }
    const onVisibilityChange = (modifiedColumns) => {
        const _targetColumns = [...gridColumns];
        for (let i = 0; i < modifiedColumns.length; i++) {
            const _targetColumnIndex = _targetColumns.findIndex((e) => e.id === modifiedColumns[i].id);
            if (_targetColumnIndex !== null) {
                _targetColumns[_targetColumnIndex].visible = modifiedColumns[i].visible;
            }
        }
        setGridColumns(_targetColumns);
        hideVisibilitySetting();
    }

    function reloadGrid() {
        const columns = props.columns.map((column, idx) => ({
            id: idx,
            allowSort: true,
            allowFilter: true,
            allowDownload: true,
            visibilitySetting: false,
            filterType: 'text',
            filterRangeValues: [],
            filterOperator: "=",
            filterText: '',
            filterFromDate: '',
            filterToDate: '',
            visible: true,
            visibilitySetting: true,
            renderer: null,
            ...column,
        }))

        setGridColumns(columns);
        setGridOrder({
            orderBy: props.orderBy,
            orderByType: props.orderByType,
            defaultOrderBy: props.defaultOrderBy,
            defaultOrderByType: props.defaultOrderByType
        })
    }
    const hideFilter = () => {
        setFilterModal(prevState => ({
            ...prevState,
            show: false,
        }));
    }

    const showFilter = (columnId) => {

        // if (loadingData) return;

        const _targetColumn = gridColumns.find((e) => e.id === columnId);

        if (_targetColumn !== null) {
            setFilterModal({
                column: _targetColumn,
                show: true,
            });
        }

    };
    const onFilter = (filterResult) => {
        hideFilter();
        console.log((filterResult))

        const gridTemp = gridColumns.slice();
        const _column = gridTemp.find((e) => e.id === filterResult.columnId);

        if (_column &&
            (_column.filterText !== filterResult.filterText || _column.filterOperator !== filterResult.filterOperator ||
                _column.filterFromDate !== filterResult.filterFromDate || _column.filterToDate !== filterResult.filterToDate)
        ) {
            _column.filterText = filterResult.filterText;
            _column.filterOperator = filterResult.filterOperator;
            _column.filterFromDate = filterResult.filterFromDate
            _column.filterToDate = filterResult.filterToDate
            setGridColumns(gridTemp);
        }

    }




    // change ordering
    const changeOrdering = (columnId) => {
        const _targetColumn = gridColumns.find((e) => e.id === columnId);

        if (_targetColumn) {
            if (_targetColumn.databaseColName === gridOrder.orderBy && gridOrder.orderByType === 'ASC') {
                setGridOrder(prev => ({
                    ...prev,
                    orderBy: _targetColumn.databaseColName,
                    orderByType: 'DESC',
                }));
            } else if (_targetColumn.databaseColName === gridOrder.orderBy && gridOrder.orderByType === 'DESC') {
                setGridOrder(prev => ({
                    ...prev,
                    orderBy: null,
                    orderByType: null,
                }));
            } else {
                setGridOrder(prev => ({
                    ...prev,
                    orderBy: _targetColumn.databaseColName,
                    orderByType: 'ASC',
                }));
            }
        }
    };
    // function filterApply() {
    //     setGridColumns((prev) => {
    //         return { ...prev, }
    //     })
    // }
    useEffect(() => {
        fetchData(1);
    }, [gridOrder, gridColumns]);

    useEffect(() => {
        if (props.refreshCounter > 1) {
            fetchData(pagination.currentPage);
        }
    }, [props.refreshCounter]);

    async function fetchData(pageNumber) {
        const filterParams = {};
        let filterParamCount = 1;
        setIsLoading(true);
        console.log(gridColumns)
        for (let i = 0; i < gridColumns.length; i++) {
            if ((gridColumns[i].filterFromDate != '' && gridColumns[i].filterToDate != '') || gridColumns[i].filterText != '' || gridColumns[i].filterOperator == 'isEmpty') {
                filterParams['filter_field_' + filterParamCount.toString()] = gridColumns[i].databaseColName;
                filterParams['filter_op_' + filterParamCount.toString()] = gridColumns[i].filterOperator;
                filterParams['filter_val_' + filterParamCount.toString()] = gridColumns[i].filterText;
                filterParams['filter_from_date_val_' + filterParamCount.toString()] = gridColumns[i].filterFromDate;
                filterParams['filter_to_date_val_' + filterParamCount.toString()] = gridColumns[i].filterToDate;
                filterParamCount += 1;
            }
        }
        try {
            const authorization = localStorage.getItem('auth_token');
            const offset = (pageNumber * props.recordsPerPage) - props.recordsPerPage;
            const params = {
                offset: offset,
                limit: props.recordsPerPage,
                orderBy: gridOrder.orderBy !== '' ? gridOrder.orderBy : '',
                orderByType: gridOrder.orderByType !== '' ? gridOrder.orderByType : '',
                defaultOrderBy: gridOrder.defaultOrderBy,
                defaultOrderByType: gridOrder.defaultOrderByType,
                ...filterParams
            };

            const response = await sendHttpRequest(`${apiUrl}/${props.dataSourceUrl}`, authorization, params);


            if (response.status === 200) {
                const data = await response.json();
                setGridData(data.dataSource); // Store the fetched data in state
                setPagination({
                    currentPage: pageNumber,
                    recordsPerPage: props.recordsPerPage,
                    totalRecords: Number(data.totalRecords.count)
                });
                if (data.dataSource.length > 0) {
                    setFooterSummary(`Showing ${offset + 1} to ${offset + data.dataSource.length} of ${data.totalRecords.count} `)
                }
                else {
                    setFooterSummary(`No Record Found.`)
                }
            } else {
                console.error('Failed to fetch data:', response.statusText);
            }
            setIsLoading(false)
        }
        catch (error) {
            console.error('Error fetching data:', error.message);
            setIsLoading(false)
        }
    }
    const download = () => {

        setDownloading(true);

        const filterParams = {};
        let filterParamCount = 1;



        for (let i = 0; i < gridColumns.length; i++) {
            console.log(gridColumns[i])
            if ((gridColumns[i].filterFromDate != '' && gridColumns[i].filterToDate != '') || gridColumns[i].filterText != '') {
                filterParams['filter_field_' + filterParamCount.toString()] = gridColumns[i].databaseColName;
                filterParams['filter_op_' + filterParamCount.toString()] = gridColumns[i].filterOperator;
                filterParams['filter_val_' + filterParamCount.toString()] = gridColumns[i].filterText;
                filterParams['filter_from_date_val_' + filterParamCount.toString()] = gridColumns[i].filterFromDate;
                filterParams['filter_to_date_val_' + filterParamCount.toString()] = gridColumns[i].filterToDate;
                filterParamCount += 1;
            }
        }


        let fieldPlaceCount = 1;
        const fieldPlaces = {};
        for (let i = 0; i < gridColumns.length; i++) {
            console.log(gridColumns[i])
            if (gridColumns[i].allowDownload === true && gridColumns[i].visible === true && gridColumns[i].databaseColName !== null && gridColumns[i].databaseColName !== undefined) {
                filterParams['field_' + fieldPlaceCount.toString()] = gridColumns[i].databaseColName;
                filterParams['title_' + fieldPlaceCount.toString()] = gridColumns[i].headerName;
                fieldPlaceCount += 1;
            }
        }
        const authorization = localStorage.getItem('auth_token');
        const params = {

            orderBy: gridOrder.orderBy !== '' ? gridOrder.orderBy : '',
            orderByType: gridOrder.orderByType !== '' ? gridOrder.orderByType : '',
            defaultOrderBy: gridOrder.defaultOrderBy,
            defaultOrderByType: gridOrder.defaultOrderByType,
            download: 1,
            ...filterParams,
            ...fieldPlaces
        };

        sendHttpRequest(`${apiUrl}/${props.dataSourceUrl}`, authorization, params).then(async (resp) => {
            if (resp.ok) {
                const blob = await resp.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                // const contDisp = resp.headers.get('Content-Disposition');
                // link.setAttribute('download', contDisp.substring(contDisp.indexOf('filename=') + 9));
                link.setAttribute('download', 'downloadFileName' in props ? props['downloadFileName'] : 'untitled');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setDownloading(false);
            } else {
                setDownloading(false);
                //error
            }
        }).catch((e) => {
            setDownloading(false);
            //error
        });
    }

    return (
        <>
            {/*Visibility Modal*/}
            {showVisibilityModal &&
                <GridColumnVisibilityModal
                    onHide={hideVisibilitySetting}
                    columns={gridColumns.filter((e) => e.databaseColName !== null && e.databaseColName !== undefined).map((e) => { return { headerName: e.headerName, visible: e.visible, id: e.id, visibilitySetting: e.visibilitySetting } })}
                    onSave={onVisibilityChange}
                />
            }
            {/* Filter Modal */}
            {filterModal.show && (<GridFilter filterModal={filterModal} setFilterModal={setFilterModal} hideFilter={hideFilter} applyFilterHandler={onFilter} />)}
            {/* Filter Modal */}
            < div className="relative" >
                <div className="flex flex-col  py-2 rounded-md shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                    {/* Table Header */}
                    <div className="flex justify-between items-center px-4 py-2 ">
                        <div className="flex flex-col">
                            <span className="text-base font-medium text-orange-600">{props.title}</span>
                            <span className="text-xs font-medium text-gray-500">{props.subTitle}</span>
                        </div>
                        <div className="flex gap-1">
                            <div className="flex justify-end gap-x-1">
                                {
                                    props.linkButton?.map((e) => (
                                        <LinkButton to={e.to} children={e.children} className={e.className} />
                                    ))
                                }
                                <div className="hover:text-white relative font-extrabold cursor-pointer rounded-[4px] hover:bg-orange-500 bg-gray-200  w-8 min-w-fit p-1 h-8 flex  items-center justify-center" onClick={showVisibilitySetting} >
                                    <Eye />
                                </div>
                                {props.allowDownload && <div className="hover:text-white relative font-extrabold cursor-pointer rounded-[4px] hover:bg-orange-500 bg-gray-200  w-8 min-w-fit p-1 h-8 flex  items-center justify-center" onClick={download}>
                                    {downloading ? <ArrowClockwise className="animate-spin" /> : <Download />}
                                </div>}
                                <div className="hover:text-white relative font-extrabold cursor-pointer rounded-[4px] hover:bg-orange-500 bg-gray-200  w-8 min-w-fit p-1 h-8 flex  items-center justify-center" onClick={reloadGrid}>
                                    <ArrowClockwise />
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* Table Body */}
                    {/* <div className="w-full overflow-x-auto ">
                        <table className="w-full shadow-md rounded-md ">
                            <thead className="border-t border-b border-gray-300">
                                <tr >
                                    {gridColumns.map((col, idx) => {
                                        return col.visible ? (
                                            <th key={idx} scope="col" className="text-left text-[13px] font-semibold py-2 bg-gray-100 whitespace-nowrap">
                                                <div className="flex items-center justify-center gap-x-1 px-2">
                                                    <span>{col.headerName}</span>
                                                    {col.allowSort && gridOrder.orderBy != col.databaseColName && (<div className="text-[15px] text-gray-400 cursor-pointer" onClick={() => { changeOrdering(col.id) }}><ArrowDownUp /></div>)}
                                                    {gridOrder.orderBy === col.databaseColName && gridOrder.orderByType === 'DESC' && (<div className="text-[19px] font-bold text-orange-600 cursor-pointer" onClick={() => changeOrdering(col.id)}><SortAlphaUp /></div>)}
                                                    {gridOrder.orderBy === col.databaseColName && gridOrder.orderByType === 'ASC' && (<div className="text-[19px] text-bold text-orange-600 cursor-pointer" onClick={() => changeOrdering(col.id)}><SortAlphaDown /></div>)}
                                                    {col.allowFilter && (<div className="text-[15px] text-gray-400 cursor-pointer" onClick={() => showFilter(col.id)}>
                                                        {(col.filterType == 'dateRange' && (col.filterFromDate != '' && col.filterToDate != '')) || (col.filterType != 'dateRange' && col.filterOperator != "IsEmpty" && col.filterText != '') || col.filterOperator == "isEmpty" ? (<FunnelFill className="text-orange-500" />) : (<Funnel />)}</div>)}
                                                </div>
                                            </th>
                                        ) : null;
                                    })}
                                </tr>
                            </thead>
                            <tbody className="text-gray-800">
                                {isLoading ? (
                                    Array.from({ length: props.recordsPerPage }, (_, i) => (
                                        <tr key={i} className="even:bg-gray-100 animate-pulse">
                                            {gridColumns.map((col, colIdx) => {
                                                if (col.visible) {
                                                    return (<td className="text-[13px]  text-center py-3 px-2 whitespace-nowrap">
                                                        <div className="bg-gray-300 rounded-xl h-[0.730rem]">&nbsp;</div>
                                                    </td>)
                                                }
                                            })}
                                        </tr>
                                    ))
                                ) : gridData.map((data, rowIdx) => (
                                    <tr key={rowIdx} className="even:bg-gray-100 hover:bg-gray-200 cursor-pointer">
                                        {gridColumns.map((col, colIdx) => {
                                            if (col.visible) {
                                                if (col.renderer) {
                                                    return <td className="text-[13px]  text-center py-2 px-2 whitespace-nowrap">{col.renderer(data)}</td>;
                                                }
                                                else {
                                                    return <td  className="text-[13px]  text-center py-2 px-2 whitespace-nowrap">{data[col.databaseColName]}</td>;
                                                }
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div> */}
                    <div className="w-full overflow-x-auto">
                        <table className="w-full shadow-md rounded-md">
                            <thead className="border-t border-b border-gray-300">
                                <tr>
                                    {gridColumns.map((col, idx) => col.visible && (
                                        <th key={`header-${idx}`} scope="col" className="text-left text-[13px] font-semibold py-2 bg-gray-100 whitespace-nowrap">
                                            <div className="flex items-center justify-center gap-x-1 px-2">
                                                <span>{col.headerName}</span>
                                                {col.allowSort && gridOrder.orderBy !== col.databaseColName && (
                                                    <div className="text-[15px] text-gray-400 cursor-pointer" onClick={() => changeOrdering(col.id)}>
                                                        <ArrowDownUp />
                                                    </div>
                                                )}
                                                {gridOrder.orderBy === col.databaseColName && gridOrder.orderByType === 'DESC' && (
                                                    <div className="text-[19px] font-bold text-orange-600 cursor-pointer" onClick={() => changeOrdering(col.id)}>
                                                        <SortAlphaUp />
                                                    </div>
                                                )}
                                                {gridOrder.orderBy === col.databaseColName && gridOrder.orderByType === 'ASC' && (
                                                    <div className="text-[19px] text-bold text-orange-600 cursor-pointer" onClick={() => changeOrdering(col.id)}>
                                                        <SortAlphaDown />
                                                    </div>
                                                )}
                                                {col.allowFilter && (
                                                    <div className="text-[15px] text-gray-400 cursor-pointer" onClick={() => showFilter(col.id)}>
                                                        {(col.filterType === 'dateRange' && col.filterFromDate !== '' && col.filterToDate !== '') ||
                                                            (col.filterType !== 'dateRange' && col.filterOperator !== "IsEmpty" && col.filterText !== '') ||
                                                            col.filterOperator === "isEmpty" ? (
                                                            <FunnelFill className="text-orange-500" />
                                                        ) : (
                                                            <Funnel />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="text-gray-800">
                                {isLoading ? (
                                    Array.from({ length: props.recordsPerPage }, (_, i) => (
                                        <tr key={`loading-${i}`} className="even:bg-gray-100 animate-pulse">
                                            {gridColumns.map((col, colIdx) => col.visible && (
                                                <td key={`loading-cell-${i}-${colIdx}`} className="text-[13px] text-center py-3 px-2 whitespace-nowrap">
                                                    <div className="bg-gray-300 rounded-xl h-[0.730rem]">&nbsp;</div>
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                ) : (
                                    gridData.map((data, rowIdx) => (
                                        <tr key={`row-${rowIdx}`} className="even:bg-gray-100 hover:bg-gray-200 cursor-pointer">
                                            {gridColumns.map((col, colIdx) => col.visible && (
                                                <td key={`cell-${rowIdx}-${colIdx}`} className="text-[13px] text-center py-2 px-2 whitespace-nowrap">
                                                    {col.renderer ? col.renderer(data) : data[col.databaseColName]}
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* Pagination & Footer Summary */}
                    <div className="flex flex-col justify-between items-center py-2 px-4 border-t border-gray-300 gap-1 md:flex-row">
                        <span className="text-[13px] font-medium text-gray-600">{footerSummary}</span>
                        <Pagination
                            recordsPerPage={pagination.recordsPerPage}
                            totalRecords={pagination.totalRecords}
                            currentPage={pagination.currentPage}
                            changePageTo={changePageTo}
                        />
                    </div>
                </div >
            </div >
        </>
    )
};

export default Grid;