const apiUrl = process.env.REACT_APP_API_URL;
export async function getReasonList(fault_type) {
    try {
        const response = await fetch(`${apiUrl}/utils/getReasonList`, {
            method: 'POST',
            body: JSON.stringify({ faultType: fault_type }),
            headers: {
                'Authorization': "bearer " + localStorage.getItem('auth_token'),
                'Content-Type': 'application/json'
            }
        });
        const responseData = await response.json()
        return responseData
    }
    catch (error) {
        console.log(error)
    }
}