import React, { useContext, useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import Header from './Header'
import Alert from "./Alert";


const MainLayout = () => {
    const [message, setMessage] = useState('');
    const [visiblePopup, setVisiblePopup] = useState(false);

    return (
        <div className="flex flex-col  bg-gray-50 font-inter">
            <div>
                {visiblePopup ? (<Alert messageType={"error"} message={message} setVisiblePopup={setVisiblePopup} />) : ''}
            </div>
            <div>
                <Header />
                <Outlet />
            </div>
        </div>
    )
}

export default MainLayout;