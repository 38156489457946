import React from 'react'
import { useState } from 'react';
import { XCircle } from "react-bootstrap-icons";
import Toast from './Toast';
import Loader from "./Loader";
const apiUrl = process.env.REACT_APP_API_URL;

const ConvertManualToProtectionNTModal = (props) => {
    const [visiblePopup, setVisiblePopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [toastVisible, setToastVisible] = useState(false);
    const [message, setMessage] = useState('');
    let error;
    const toastHide = () => {
        setMessage('');
        setToastVisible(false);
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        error = { message: [] }
        setLoader(true); //



        try {
            const response = await fetch(`${apiUrl}/utils/convertManualToProtection`, {
                method: 'POST',
                body: JSON.stringify({
                    id: props.currentFaultId,
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "authorization": 'bearer ' + localStorage.getItem('auth_token')
                }
            });

            const responseData = await response.json();

            if (response.ok) {
                props.setGridRefCnt((prv) => ++prv);
                props.onHide();
            }
            else {
                setMessage(responseData.message);
                setToastVisible(true)
            }

        } catch (error) {
            console.error(error);
            setMessage(error);
            setToastVisible(true)
        }


        setLoader(false)


    };


    return (
        <>
            {loader && <Loader />}
            {/* {visiblePopup && (<Alert messageType={"error"} message={message} setVisiblePopup={setVisiblePopup} />)} */}

            <div className="  fixed inset-0 flex items-start  justify-center z-10 backdrop-blur-sm cursor-pointer" id="popupBG-1" >
                <div className="absolute inset-0 bg-black opacity-30 w-full " id="popupBG-2"></div>
                {toastVisible && (<Toast message={message} toastHide={toastHide} />)}
                <div className="animate-fade-in-up bg-white  m-2 z-10 lg:w-[50%] shadow-lg " id="popupBG-3">
                    <div className=" ">
                        <div className="flex bg-gray-100 px-5 py-3 flex-row justify-between items-center  border-b border-gray-300 ">
                            <div className="text-lg font-semibold text-orange-600 flex items-center"><span>Confirmation </span></div>
                            <div className="  hover:text-orange-500 my-1 text-xl font-semibold" onClick={props.onHide}><XCircle /></div>
                        </div>
                    </div>
                    <div className='px-8 py-4 pb-10'>
                        <div className="text-lg Capitalize  py-2 px-2 font-semibold">
                            Do you really want to convert 'Operation Type' from <span className='text-sky-500'>Manual</span> to <span className='text-sky-500'>Protection</span> ?
                        </div>

                    </div>

                    <div className="w-full bg-gray-100 text-right py-3 px-5 border-t border-gray-300">
                        <button className="w-18 bg-gray-800 hover:bg-black text-white text-sm font-semibold py-1.5 px-4 mr-1 rounded-md" onClick={props.onHide}>
                            Cancel
                        </button>
                        <button className="w-18 bg-orange-600 hover:bg-black text-white text-sm font-semibold py-1.5 px-4 rounded-md" onClick={handleOnSubmit}>
                            Confirm & Save
                        </button>
                    </div>

                </div >
            </div >
        </>
    )
}

export default ConvertManualToProtectionNTModal