import React from 'react'
import { useState } from 'react';
import { XCircle, CaretDownFill, CaretUpFill, ArrowClockwise } from "react-bootstrap-icons";
import Alert from "./Alert";
import Toast from './Toast';
import Loader from "./Loader";
const apiUrl = process.env.REACT_APP_API_URL;

const ConvertManualToProtectionSFModal = (props) => {

    const [visiblePopup, setVisiblePopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [toastVisible, setToastVisible] = useState(false);
    const [message, setMessage] = useState('');

    const [relayFlag, setRelayFlag] = useState(null);
    const [brokenConductor, setBrokenConductor] = useState(null);
    const [phase, setPhase] = useState(null);
    const [faultCurrent, setFaultCurrent] = useState({ R: null, Y: null, B: null, G: null, N: null })
    const relayFlagValidList = [
        "Over Current",
        "Earth Fault",
        "Over Current & Earth Fault",
        "Over Current Highset",
        "Earth Fault Highset",
        "Over Current Highset & Earth Fault Highset"]

    let error;
    const toastHide = () => {
        setMessage('');
        setToastVisible(false);
    }
    const handleFaultCurrent = async (key, value) => {
        try {
            setFaultCurrent(() => {
                return { ...faultCurrent, [key]: parseFloat(value) }
            })
        }
        catch (error) {
            console.log(error);
        }
    }

    const validation = async () => {
        if (relayFlag == null || brokenConductor == null || typeof relayFlag == undefined || typeof brokenConductor == undefined || relayFlag == '' || brokenConductor == '') {
            error.message.push('Relay Flag & Broken Conductor are required');
            return

        }
        if (!relayFlagValidList.includes(relayFlag)) {
            error.message.push('Select valid option of Relay Flag.')
        }
        if (!['Yes', 'No'].includes(brokenConductor)) {
            error.message.push('Select valid option of Broken Conductor.')
        }
    }

    console.log(faultCurrent)

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        error = { message: [] }
        setLoader(true); //

        await validation(); // Check validation
        if (error.message.length <= 0) {
            try {
                const response = await fetch(`${apiUrl}/utils/convertManualToProtection`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: props.currentFaultId,
                        relayFlag: relayFlag,
                        brokenConductor: brokenConductor,
                        phase: phase,
                        faultCurrent: faultCurrent
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "authorization": "bearer " + localStorage.getItem('auth_token')
                    }
                });

                const responseData = await response.json();

                if (response.ok) {
                    props.setGridRefCnt((prv) => ++prv);
                    props.onHide();
                }
                else {
                    setMessage(responseData.message);
                    setToastVisible(true)
                }

            } catch (error) {
                console.error(error);
                setMessage(error);
                setToastVisible(true)
            }

        }
        else {
            setMessage(error.message)
            setToastVisible(true)
        }
        setLoader(false)


    };


    return (
        <>
            {loader && <Loader />}
            {/* {visiblePopup && (<Alert messageType={"error"} message={message} setVisiblePopup={setVisiblePopup} />)} */}

            <div className="  fixed inset-0 flex items-start  justify-center z-10 backdrop-blur-sm cursor-pointer" id="popupBG-1" >
                <div className="absolute inset-0 bg-black opacity-30 w-full " id="popupBG-2"></div>
                {toastVisible && (<Toast message={message} toastHide={toastHide} />)}
                <div className="animate-fade-in-up bg-white  m-2 z-10 lg:w-[50%] shadow-lg " id="popupBG-3">
                    <div className=" ">
                        <div className="flex bg-gray-100 px-5 py-3 flex-row justify-between items-center  border-b border-gray-300 ">
                            <div className="text-lg font-semibold text-orange-600 flex items-center"><span>Confirmation </span></div>
                            <div className="  hover:text-orange-500 my-1 text-xl font-semibold" onClick={props.onHide}><XCircle /></div>
                        </div>
                    </div>
                    <div className='px-8 py-4 pb-10'>
                        <div className="text-lg Capitalize  py-2 px-2 font-semibold">
                            Do you really want to convert 'Operation Type' from <span className='text-sky-500'>Manual</span> to <span className='text-sky-500'>Protection</span> ?
                        </div>
                        <div className='pt-4 px-2 grid grid-cols-1 lg:grid-cols-3 gap-x-2 gap-y-1'>
                            <div className='flex flex-col'>
                                <label className='block text-sm font-medium leading-6 text-gray-900'>Relay flag <span className='text-red-500'>*</span></label>
                                <select className="p-1.5 border  border-gray-400 focus:ring-slate-600 rounded-md text-gray-500 outline-none" onChange={(e) => { setRelayFlag(e.target.value); }}>
                                    <option value='' >Select</option>
                                    <option value="Over Current">Over Current</option>
                                    <option value="Earth Fault">Earth Fault</option>
                                    <option value="Over Current & Earth Fault">Over Current & Earth Fault</option>
                                    <option value="Over Current Highset">Over Current Highset</option>
                                    <option value="Earth Fault Highset">Earth Fault Highset</option>
                                    <option value="Over Current Highset & Earth Fault Highset">Over Current Highset & Earth Fault Highset</option>
                                </select>
                            </div>
                            <div className='flex flex-col'>
                                <label className='block text-sm font-medium leading-6 text-gray-900'>Broken Conductor <span className='text-red-500'>*</span></label>
                                <select className="p-1.5 border  border-gray-400 focus:ring-slate-600 rounded-md text-gray-500 outline-none" onChange={(e) => { setBrokenConductor(e.target.value); }}>
                                    <option value='' >Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className='flex flex-col'>
                                <label className='block text-sm font-medium leading-6 text-gray-900'>Phase</label>
                                <select className="p-1.5 border  border-gray-400 focus:ring-slate-600 rounded-md text-gray-500 outline-none" onChange={(e) => { setPhase(e.target.value); }}>
                                    <option value='' >Select</option>
                                    <option value="R">R</option>
                                    <option value="Y">Y</option>
                                    <option value="B">B</option>
                                    <option value="RY">RY</option>
                                    <option value="YB">YB</option>
                                    <option value="RB">RB</option>
                                    <option value="RYB">RYB</option>
                                </select>
                            </div>
                        </div>
                        <div className='mt-3 px-2 flex flex-wrap gap-4 items-center justify-start'>
                            <div className='flex flex-col w-full'>
                                <label className='block text-sm font-medium leading-6 text-gray-900 py-1'>Fault Current</label>
                                <div className='grid lg:grid-cols-5 lg:gap-x-2 gap-y-1 grid-cols-2 gap-x-2'>
                                    <div className="relative flex items-center border border-gray-400 rounded-md">
                                        <span className="absolute inset-y-0 left-0 flex items-center px-3 bg-gray-300 rounded-l-md">
                                            <span className="block text-sm font-medium leading-6 text-gray-900">R</span>
                                        </span>
                                        <input type="number" step="0.1" min="0" className="pl-10 rounded-md 2 px-4 outline-none w-full" onChange={(e) => { handleFaultCurrent('R', e.target.value); }} />
                                    </div>
                                    <div class="relative flex items-center border border-gray-400 rounded-md">
                                        <span class="absolute inset-y-0 left-0 flex items-center px-3 bg-gray-300 rounded-l-md">
                                            <span class="block text-sm font-medium leading-6 text-gray-900">Y</span>
                                        </span>
                                        <input type="number" step="0.1" min="0" className="pl-10 rounded-md py-2 px-4 outline-none w-full" onChange={(e) => { handleFaultCurrent('Y', e.target.value); }} />
                                    </div>
                                    <div class="relative flex items-center border border-gray-400 rounded-md">
                                        <span class="absolute inset-y-0 left-0 flex items-center px-3 bg-gray-300 rounded-l-md">
                                            <span class="block text-sm font-medium leading-6 text-gray-900">B</span>
                                        </span>
                                        <input type="number" step="0.1" min="0" className="pl-10 rounded-md py-2 px-4 outline-none w-full" onChange={(e) => { handleFaultCurrent('B', e.target.value); }} />
                                    </div>
                                    <div class="relative flex items-center border border-gray-400 rounded-md">
                                        <span class="absolute inset-y-0 left-0 flex items-center px-3 bg-gray-300 rounded-l-md">
                                            <span class="block text-sm font-medium leading-6 text-gray-900">G</span>
                                        </span>
                                        <input type="number" step="0.1" min="0" className="pl-10 rounded-md py-2 px-4 outline-none w-full" onChange={(e) => { handleFaultCurrent('G', e.target.value); }} />
                                    </div>
                                    <div class="relative flex items-center border border-gray-400 rounded-md">
                                        <span class="absolute inset-y-0 left-0 flex items-center px-3 bg-gray-300 rounded-l-md">
                                            <span class="block text-sm font-medium leading-6 text-gray-900">N</span>
                                        </span>
                                        <input type="number" step="0.1" min="0" className="pl-10 rounded-md py-2 px-4 outline-none w-full" onChange={(e) => { handleFaultCurrent('N', e.target.value); }} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="w-full bg-gray-100 text-right py-3 px-5 border-t border-gray-300">
                        <button className="w-18 bg-gray-800 hover:bg-black text-white text-sm font-semibold py-1.5 px-4 mr-1 rounded-md" onClick={props.onHide}>
                            Cancel
                        </button>
                        <button className="w-18 bg-orange-600 hover:bg-black text-white text-sm font-semibold py-1.5 px-4 rounded-md" onClick={handleOnSubmit}>
                            Confirm & Save
                        </button>
                    </div>

                </div >
            </div >
        </>
    )
}

export default ConvertManualToProtectionSFModal