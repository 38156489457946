import "./style.css";
import React from "react";
import { Route, Routes } from "react-router-dom";


import RequireAuth from "./components/RequireAuth1";
import MainLayout from "./components/MainLayout";
import Login from "./pages/auth/login";
import Home from "./components/Home"
import Summary from "./components/Summary"
import Outages from "./components/Outages"
import AddOutage from "./components/AddOutage"
import ViewOutage from "./components/ViewOutage";
import Modules from "./components/Modules";
import Substation from "./components/Substation";
import ViewSubstation from "./components/ViewSubstation";
import AddSubstation from "./components/AddSubstation";
import ErrorPage from "./components/ErrorPage";
import EditSubstation from "./components/EditSubstation";
import PlayGround from "./components/PlayGround";
import SummaryMain from "./components/SummaryMain";

function App() {
    return (

        <Routes>

            {/* Protected Routes */}
            <Route path="/" element={<RequireAuth><MainLayout /></RequireAuth>}>

                <Route index element={<Home />} />

                <Route path="/summary" element={<SummaryMain />} />

                <Route path="/outages" element={<Outages />}>
                    <Route index element={<ViewOutage />} />
                    <Route path="add" element={<AddOutage />} />
                </Route>

                <Route path="/modules" element={<Modules />}></Route>

                <Route path="/substation" element={<Substation />}>
                    <Route index element={<ViewSubstation />} />
                    <Route path="create" element={<AddSubstation />} />
                    <Route path=":id" element={<EditSubstation />} />
                </Route>

            </Route>

            {/* Public Routes */}
            <Route path='/auth/login' element={<Login />} />
            <Route path='/playground' element={<PlayGround />} />

            {/* Handle 404 page */}
            <Route path='/*' element={<ErrorPage />} />
        </Routes>
    )
}

export default App;