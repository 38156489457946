// import React, { useEffect } from 'react'
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AuthContext from '../store/AuthContext';
import moment from 'moment'
import { Plus, PencilSquare, ArrowLeftRight, Clock, XCircle, ArrowRightCircle, ThreeDots, PencilFill } from "react-bootstrap-icons";

import Grid from './Grid';
import SetFaultTypeModal from "./SetFaultTypeModal"
import ConvertManualToProtectionSFModal from './ConvertManualToProtectionSFModal';
import ConvertManualToProtectionNTModal from './ConvertManualToProtectionNTModal';
import UpdateFaultCurrentModal from './UpdateFaultCurrentModal';
import AddManualEndTimeModal from './AddManualEndTimeModal';



const ActionDropdown = (props) => {
    const { options, onHide } = props;
    return (
        <div className="  fixed inset-0 flex items-start  justify-center z-10 backdrop-blur-sm cursor-pointer" id="popupBG-1" >
            <div className="absolute inset-0 bg-black opacity-30 w-full" id="popupBG-2"></div>
            <div className="animate-fade-in-up bg-white m-2 z-10 md:w-1/4 shadow-lg" id="popupBG-3">
                <div className=" w-full">
                    <div className="flex bg-gray-100 px-4 py-2 flex-row justify-between items-center  border-b border-gray-300 ">
                        <div className="text-base font-semibold text-orange-600 flex items-center"><span>Action</span></div>
                        <div className="  hover:text-orange-500 my-1 text-xl font-semibold" ><XCircle onClick={onHide} /></div>
                    </div>
                </div>
                <ul>
                    {options.map((option) => (
                        <li className='flex items-center justify-between px-4 py-3 text-sm hover:bg-gray-100' onClick={() => {
                            onHide();
                            option.onClick(option.id);
                        }}>
                            <div className='flex items-center gap-x-2'>
                                <span className='text-gray-500'>{option.icon}</span>
                                <span className='text-sm'>{option.label}</span>
                            </div>
                            <span className='text-lg'><ArrowRightCircle /></span>
                        </li>

                    ))}
                </ul>

            </div>
        </div >)
}

const ViewOutage = () => {

    const REACT_APP_ADD_OUTAGE_URL = process.env.REACT_APP_ADD_OUTAGE_URL
    const [showFaultTypeModal, setShowFaultTypeModal] = useState(false);
    const [showConvertManualToProtectionNTModal, setConvertManualToProtectionNTModal] = useState(false);
    const [showConvertManualToProtectionSFModal, setConvertManualToProtectionSFModal] = useState(false);
    const [showUpdateFaultCurrentModal, setShowUpdateFaultCurrentModal] = useState(false);
    const [showAddManualEndTimeModal, setShowAddManualEndTimeModal] = useState(false);
    const [currentFaultId, setCurrentFaultId] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState();
    const [faultType, setFaultType] = useState(false);
    const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
    const [gridRefCnt, setGridRefCnt] = useState(1);
    const [searchParams] = useSearchParams();
    const [faultDetails, setFaultDetails] = useState()

    //Default Search
    const defaultFeederId = searchParams.get('fid');
    const outageTypePending = searchParams.get('outagetypepending')

    const handleFaultTypeModal = (id) => {
        setCurrentFaultId(id);
        setShowFaultTypeModal(true);
    }

    const hideFaultTypeModal = () => {
        setShowFaultTypeModal(false);
    }

    const handleAddManualEndTimeModal = (id) => {
        setCurrentFaultId(id);
        setShowAddManualEndTimeModal(true)
    }
    const hideAddManualEndTimeModal = (id) => {
        setShowAddManualEndTimeModal(false)
    }

    const handleFaultCurrentModal = (id) => {
        setCurrentFaultId(id);
        setShowUpdateFaultCurrentModal(true);
    }
    const hideFaultCurrentModal = (id) => {
        setShowUpdateFaultCurrentModal(false);
    }
    //--------------------------------------
    const handleConvertManualToProtectionSFModal = (fault) => {
        setCurrentFaultId(fault.id);
        setConvertManualToProtectionSFModal(true);
        setFaultDetails(fault)

    }
    const hideConvertManualToProtectionSFModal = () => {
        setConvertManualToProtectionSFModal(false);
    }
    const handleConvertManualToProtectionNTModal = (fault) => {
        setCurrentFaultId(fault.id);
        setConvertManualToProtectionNTModal(true);
        setFaultDetails(fault)
    }
    const hideConvertManualToProtectionNTModal = () => {
        setConvertManualToProtectionNTModal(false);
    }
    // const addManualEndDateTime = (fault) =>{
    //     return (

    //     )
    // }
    const [openActionDropdown, setOpenActionDropdown] = useState(false)
    const [actionDropDownOptions, setActionDropDownOptions] = useState();

    const hideOpenActionDropDown = () => {

        setOpenActionDropdown(false);
        setActionDropDownOptions();

    }

    const generateActionOptions = (outage) => {
        console.log(authCtx.user)
        let options = []
        if (authCtx.user.companyName === 'GETCO' && authCtx.user.substationName != "") {
            const manualList = ['0', '1', '2', '4'];
            // Example of dynamic option generation
            if (outage.fault_end_date_time == null || outage.fault_end_date_time == undefined || outage.fault_end_date_time == '') {
                options.push({
                    id: outage.id,
                    icon: <Clock />,
                    label: "Add End Date/Time of Outage",
                    onClick: () => handleAddManualEndTimeModal(outage.id)
                })
            }
            if (manualList.includes(outage.interruption_type) && ([null, '5', '6', '7', '8', '9'].includes(outage.fault_type) == true)) {
                options.push({
                    id: outage.id,
                    icon: <PencilFill />,
                    label: "Update ESD/PSD Details",
                    onClick: () => handleFaultTypeModal(outage.id)
                })
            }
            if (outage.interruption_type == '3' && (outage.fault_type == '4')) {
                options.push({
                    id: outage.id,
                    icon: <PencilFill />,
                    label: "Update Fault Current Details",
                    onClick: () => handleFaultCurrentModal(outage.id)
                })
            }
            if (manualList.includes(outage.interruption_type) && (outage.fault_type == null) && (outage.fault_end_date_time != null && outage.fault_end_date_time != '') && (outage.duration_based_fault_type == 'Normal TT')) {
                options.push({
                    id: outage.id,
                    icon: <ArrowLeftRight />,
                    label: "Convert Manual to Protection",
                    onClick: () => handleConvertManualToProtectionNTModal(outage)
                })
            }
            else if (manualList.includes(outage.interruption_type) && (outage.fault_type == null) && (outage.fault_end_date_time != null && outage.fault_end_date_time != '') && (outage.duration_based_fault_type == 'Sustain Fault')) {
                options.push({
                    id: outage.id,
                    icon: <ArrowLeftRight />,
                    label: "Convert Manual to Protection",
                    onClick: () => handleConvertManualToProtectionSFModal(outage)
                })
            }
        }
        // else {
        //     options.push({
        //         id: outage.id,
        //         icon: <PencilFill />,
        //         label: "Add Reason",
        //         onClick: (e) => {
        //             window.open('http://google.com', '_blank')
        //         }
        //     })
        // }

        return options
    };

    const toggleActionDropdown = (outage) => {

        setOpenActionDropdown((prev) => !prev);
        const options = generateActionOptions(outage);
        setActionDropDownOptions([...options])

    };

    const GenerateDropdown = (outage) => {
        console.log(authCtx.user)
        if (authCtx.user.companyName === 'GETCO' && authCtx.user.substationName != "") {
            const manualList = ['0', '1', '2', '4'];
            // Example of dynamic option generation
            if (
                (outage.fault_end_date_time == null || outage.fault_end_date_time == undefined || outage.fault_end_date_time == '') ||
                (manualList.includes(outage.interruption_type) && ([null, '5', '6', '7', '8', '9'].includes(outage.fault_type) == true)) ||
                (outage.interruption_type == '3' && (outage.fault_type == '4')) ||
                (manualList.includes(outage.interruption_type) && (outage.fault_type == null) && (outage.fault_end_date_time != null && outage.fault_end_date_time != '') && (outage.duration_based_fault_type == 'Normal TT')) ||
                (manualList.includes(outage.interruption_type) && (outage.fault_type == null) && (outage.fault_end_date_time != null && outage.fault_end_date_time != '') && (outage.duration_based_fault_type == 'Sustain Fault'))
            ) {
                return (
                    <div className='relative' key={`div-${outage.id}`}>
                        <button className='text-2xl text-orange-500 hover:text-gray-800' onClick={() => toggleActionDropdown(outage)} key={`btn-${outage.id}`}>
                            <ThreeDots />
                        </button>
                    </div>

                )
            }
        }

        // let options = []
        // if (!
        //     (authCtx.user.companyName === 'GUVNL' ||
        //         (authCtx.user.companyName === 'GETCO' && authCtx.user.orgType !== 'Substation') ||
        //         ((authCtx.user.companyName === 'GETCO' && authCtx.user.orgType == 'Substation') &&
        //             (outage.fault_end_time != null && outage.fault_end_time != undefined && outage.fault_end_time != '') ||
        //             (outage.interruption_type == 3 && outage.fault_type == 3)))
        // ) {
        //     {
        //         return (
        //             <div className='relative' key={`div-${outage.id}`}>
        //                 <button className='text-2xl text-orange-500 hover:text-gray-800' onClick={() => toggleActionDropdown(outage)} key={`btn-${outage.id}`}>
        //                     <ThreeDots />
        //                 </button>
        //             </div>
        //         )
        //     }
        // }

    }

    const GenerateBtnAddManualEndTime = (outage) => {
        return (
            <button
                className="text-white cursor-pointer rounded-sm text-[14px] bg-blue-600 w-5 h-5 hover:bg-gray-700 flex items-center justify-center"
                id={outage.id}
                onClick={(e) => {
                    handleAddManualEndTimeModal(outage.id);
                }}
            ><Clock /></button >
        )
    }




    const updateFaultType = (fault) => {

        const manualList = ['0', '1', '2', '4']; //3 is protection other are manual (0-Manual,1-Central,2-Local,4-Remote)

        return (
            <>  <div className="flex gap-2">
                {manualList.includes(fault.interruption_type) && ([null, '5', '6', '7', '8', '9'].includes(fault.fault_type) == true) && (

                    <button
                        className="text-white cursor-pointer rounded-sm text-[14px] bg-orange-600 w-5 h-5 hover:bg-gray-700 flex items-center justify-center"
                        id={fault.id}
                        onClick={(e) => {
                            handleFaultTypeModal(fault.id);
                        }}
                    ><PencilSquare /></button >
                )}
                {
                    fault.interruption_type == '3' && (fault.fault_type == '4') && (
                        <button
                            className="text-white cursor-pointer shadow-md rounded-sm text-[14px] bg-green-600 w-5 h-5 hover:bg-gray-700 flex items-center justify-center"
                            id={fault.id}
                            onClick={(e) => {
                                handleFaultCurrentModal(fault.id);
                            }}
                        ><PencilSquare />
                            {/* <span className='text-gray-800 text-[14px]'>+</span>
                        <span className='text-red-600'>R</span>
                        <span className='text-yellow-600'>Y</span>
                        <span className='text-blue-600'>B</span> */}
                        </button >
                    )
                }
                {manualList.includes(fault.interruption_type) && (fault.fault_type == null) && (fault.fault_end_date_time != null && fault.fault_end_date_time != '') && (fault.duration_based_fault_type == 'Normal TT') && (

                    < button
                        className="text-white cursor-pointer rounded-sm text-[14px] bg-sky-500 w-5 h-5 hover:bg-gray-800 flex items-center justify-center"
                        id={fault.id}
                        onClick={(e) => {
                            handleConvertManualToProtectionNTModal(fault);
                        }}
                    ><ArrowLeftRight /></button >
                )}
                {manualList.includes(fault.interruption_type) && (fault.fault_type == null) && (fault.fault_end_date_time != null && fault.fault_end_date_time != '') && (fault.duration_based_fault_type == 'Sustain Fault') && (

                    <button
                        className="text-white cursor-pointer rounded-sm text-[14px] bg-sky-500 w-5 h-5 hover:bg-gray-800 flex items-center justify-center"
                        id={fault.id}
                        onClick={(e) => {
                            handleConvertManualToProtectionSFModal(fault);
                        }}
                    ><ArrowLeftRight /></button >
                )}
                {(fault.fault_end_time == null || fault.fault_end_time == undefined || fault.fault_end_time == '') && (GenerateBtnAddManualEndTime(fault))}
            </div >


            </>
        );
    }
    const updateReason = (fault) => {
        return (
            <button
                className="text-white cursor-pointer rounded-sm text-[19px] bg-orange-600 w-5 h-5 hover:bg-gray-700 flex items-center justify-center"
                id={fault.id}
                onClick={(e) => {
                    window.open('http://google.com', '_blank')
                    //handleFaultTypeModal(fault.sfms_id);
                }}
            ><Plus /></button>
        )
    }
    const mergeOutageStartDateStartTime = (data) => {
        if (data.fault_start_date_time != null && data.fault_start_date_time != undefined && data.fault_start_date_time != '') {
            return moment(data.fault_start_date_time).format("DD-MM-YYYY HH:mm:ss")
        }
        return null
    }
    const mergeOutageEndDateEndTime = (data) => {
        if (data.fault_end_date_time != null && data.fault_end_date_time != undefined && data.fault_end_date_time != '') {
            return moment(data.fault_end_date_time).format("DD-MM-YYYY HH:mm:ss");
        }
        return null
    }


    const authCtx = useContext(AuthContext);

    const [gridConfig, setGridConfig] = useState(
        {
            title: 'Outage List',
            subTitle: 'List of outage based on your organization (30 Days).',
            dataSourceUrl: 'outage',
            recordsPerPage: 10,
            allowDownload: true,
            reloadBtn: true,
            downloadFileName: 'Outages.csv',
            orderBy: 'fault_start_date_time',
            orderByType: 'DESC',
            defaultOrderBy: 'fault_start_date_time',
            defaultOrderByType: 'DESC',
            buttonList: [{
                icon: <Plus />,
                title: 'Add Outage'
            }],
            // linkButton: [{
            //     to: "./add",
            //     children: <Plus />,
            //     className: "hover:text-white relative font-extrabold cursor-pointer rounded-[4px] hover:bg-orange-500 bg-gray-200  w-8 min-w-fit p-1 h-8 flex  items-center justify-center",
            // }],
            columns: [
                ...(authCtx.user.companyName === 'GETCO' && authCtx.user.substationName != "") ? [
                    {
                        headerName: 'Action',
                        databaseColName: '',
                        visible: true,
                        visibilitySetting: false,
                        allowSort: false,
                        allowFilter: false,
                        allowDownload: false,
                        renderer: GenerateDropdown
                    }] : [],
                // {
                //     headerName: '',
                //     databaseColName: '',// 'interruption_type',
                //     visible: true,
                //     visibilitySetting: false,
                //     allowSort: false,
                //     allowFilter: false,
                //     allowDownload: false,
                //     orderBy: '',
                //     orderByType: '',
                //     renderer: updateFaultType
                // }] : [],
                ...(['DGVCL', 'MGVCL', 'PGVCL', 'UGVCL'].includes(authCtx.user.companyName) && authCtx.user.orgType === 'Sub Division') ? [{
                    headerName: 'Action',
                    databaseColName: 'interruption_type',
                    visible: false,
                    visibilitySetting: false,
                    allowSort: false,
                    allowFilter: false,
                    allowDownload: false,
                    orderBy: '',
                    orderByType: '',
                    renderer: updateReason
                }] : [],
                ...authCtx.user.companyName === 'GUVNL' || ['DGVCL', 'MGVCL', 'PGVCL', 'UGVCL'].includes(authCtx.user.companyName) ? [{
                    headerName: 'Discom',
                    databaseColName: 'company_name',
                }, {
                    headerName: 'Discom Circle',
                    databaseColName: 'circle_name',
                }, {
                    headerName: 'Discom Division',
                    databaseColName: 'division_name',
                }, {
                    headerName: 'Sub Division',
                    databaseColName: 'subdivision_name',
                }, {
                    headerName: 'Getco Circle',
                    databaseColName: 'getco_circle_name',
                    visible: false
                }, {
                    headerName: 'Getco Division',
                    databaseColName: 'getco_division_name',
                    visible: false
                }, {
                    headerName: 'Sub Station',
                    databaseColName: 'ss_name',
                }] : [],
                ...authCtx.user.companyName === 'GETCO' ? [{
                    headerName: 'Getco Circle',
                    databaseColName: 'getco_circle_name',
                    visible: false,
                }, {
                    headerName: 'Getco Division',
                    databaseColName: 'getco_division_name',
                    visible: false,
                }, {
                    headerName: 'Sub Station',
                    databaseColName: 'ss_name',
                }, {
                    headerName: 'Sub Division',
                    databaseColName: 'subdivision_name',
                },] : [],
                {
                    headerName: 'Id',
                    databaseColName: 'id',
                    visible: true,
                    filterType: 'number'
                },
                {
                    headerName: 'Feeder Id',
                    databaseColName: 'feeder_id',
                    filterType: 'number',
                    filterText: defaultFeederId || ''
                },
                {
                    headerName: 'Feeder Name',
                    databaseColName: 'feeder_name',
                },
                {
                    headerName: 'Category',
                    databaseColName: 'categor',
                },
                {
                    headerName: 'Operation Type',
                    databaseColName: 'interruption_type_text',
                    filterOperator: outageTypePending === "true" ? "notEqualTo" : '=',
                    filterText: outageTypePending === "true" ? "Protection" : ''
                },
                {
                    headerName: 'Interruption Category ',
                    databaseColName: 'outage_type_name',
                    filterOperator: outageTypePending == "true" ? "isEmpty" : '=',
                },
                {
                    headerName: 'Operation Type',
                    databaseColName: 'interruption_type',
                    visible: false,
                    visibilitySetting: false,

                },
                {
                    headerName: 'Occurrence Time',
                    databaseColName: 'fault_start_date',//'fault_start_date_time',
                    filterType: 'dateRange',
                    filterText: '',
                    filterFromDate: outageTypePending == "true" ? '2023-12-01' : moment().subtract(30, 'days').format('YYYY-MM-DD'),
                    filterToDate: moment().format('YYYY-MM-DD'),
                    renderer: mergeOutageStartDateStartTime
                },
                // {
                //     headerName: 'Occurrence Time',
                //     databaseColName: 'fault_start_date_time',
                //     filterType: 'dateRange',
                //     filterText: '',
                //     filterFromDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                //     filterToDate: moment().format('YYYY-MM-DD'),
                //     renderer: changeDateTimeFormat
                // },
                {
                    headerName: 'Restoration Time',
                    databaseColName: 'fault_end_date', //fault_end_date_time',
                    filterType: 'dateRange',

                    renderer: mergeOutageEndDateEndTime
                },
                {
                    headerName: 'Duration',
                    databaseColName: 'duration',
                    allowFilter: false,
                    filterType: 'interval'
                }]
        })


    return (
        <>
            <div>
                <ul>

                    {openActionDropdown && <ActionDropdown options={actionDropDownOptions} onHide={hideOpenActionDropDown} />}
                </ul>
            </div>

            {
                showAddManualEndTimeModal && <AddManualEndTimeModal
                    onHide={hideAddManualEndTimeModal}
                    currentFaultId={currentFaultId}
                    setGridRefCnt={setGridRefCnt}
                />
            }
            {

                showFaultTypeModal && <SetFaultTypeModal
                    onHide={hideFaultTypeModal}
                    setFaultType={setFaultType}
                    faultType={faultType}
                    currentFaultId={currentFaultId}
                    setGridRefCnt={setGridRefCnt}
                />
            }
            {
                showConvertManualToProtectionSFModal && <ConvertManualToProtectionSFModal
                    onHide={hideConvertManualToProtectionSFModal}
                    currentFaultId={currentFaultId}
                    setGridRefCnt={setGridRefCnt}
                />
            }
            {
                showConvertManualToProtectionNTModal && <ConvertManualToProtectionNTModal
                    onHide={hideConvertManualToProtectionNTModal}
                    currentFaultId={currentFaultId}
                    setGridRefCnt={setGridRefCnt}
                />
            }
            {
                showUpdateFaultCurrentModal && <UpdateFaultCurrentModal
                    onHide={hideFaultCurrentModal}
                    currentFaultId={currentFaultId}
                    setGridRefCnt={setGridRefCnt}
                />
            }
            <div className="w-full px-4 py-4 relative">
                <div className="py-5" >
                    <Grid {...gridConfig} refreshCounter={gridRefCnt} />
                </div>
            </div>
        </>
    )
}

export default ViewOutage