import React from 'react'
import Grid from './Grid';
import { useState, useEffect, useContext } from 'react';
import { Pen, PencilFill, PencilSquare, Plus } from 'react-bootstrap-icons'
import { moment } from 'moment';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../store/AuthContext';

const ViewSubstation = () => {
    const navigate = useNavigate();
    const REACT_APP_ADD_OUTAGE_URL = process.env.REACT_APP_ADD_OUTAGE_URL
    const [gridRefCnt, setGridRefCnt] = useState(1);
    const [substationList, setSubstationList] = useState()

    //Renderer Function
    function redirectToEditSubstationForm(id) {
        navigate(`/substation/${id}`)
    }
    function editSubstation(substation) {
        return (
            <button
                className="cursor-pointer text-[14px] text-orange-500  hover:text-orange-600 flex items-center justify-center"
                id={substation.id}
                onClick={() => {
                    redirectToEditSubstationForm(substation.id)
                }
                }><PencilFill /></button>
        )
    }

    //Default Search

    const authCtx = useContext(AuthContext);

    const [gridConfig, setGridConfig] = useState(
        {
            title: 'Substation List',
            subTitle: 'List of substations.',
            dataSourceUrl: 'substation',
            recordsPerPage: 10,
            allowDownload: false,
            reloadBtn: true,
            downloadFileName: 'substation.csv',
            orderBy: 'stn.id',
            orderByType: 'ASC',
            defaultOrderBy: 'stn.id',
            defaultOrderByType: 'ASC',
            linkButton: [{
                to: "./create",
                children: <Plus />,
                className: "hover:text-white relative font-extrabold cursor-pointer rounded-[4px] hover:bg-orange-500 bg-gray-200  w-8 min-w-fit p-1 h-8 flex  items-center justify-center"
            }],
            columns: [{
                headerName: 'Edit',
                databaseColName: '',
                allowFilter: false,
                allowSort: false,
                renderer: editSubstation

            }, {
                headerName: 'Id',
                databaseColName: 'id'
            },
            {
                headerName: 'Circle Name',
                databaseColName: 'cir_name'
            },
            {
                headerName: 'Division Name',
                databaseColName: 'div_name'
            },
            {
                headerName: 'SS Name',
                databaseColName: 'ss_name'
            },
            {
                headerName: 'SS Eurja Code',
                databaseColName: 'eurja_code'
            },
            {
                headerName: 'Sub Division Name',
                databaseColName: 'subdivision_name'
            },
            {
                headerName: 'Voltage Class',
                databaseColName: 'voltage_class'
            },

            {
                headerName: 'Charging Date',
                databaseColName: 'charging_date'
            },
            {
                headerName: 'Manage By',
                databaseColName: 'manage_by'
            },
            {
                headerName: 'Monitoring',
                databaseColName: 'monitoring'
            },
            {
                headerName: 'SS Category',
                databaseColName: 'ss_category'
            },
            {
                headerName: 'Coastal',
                databaseColName: 'coastal'
            },
            {
                headerName: 'Tribal',
                databaseColName: 'tribal'
            },

            ]
        })
    return (
        <div className="w-full px-4 py-4 relative">
            <div className="py-5" >
                <Grid {...gridConfig} refreshCounter={gridRefCnt} />
            </div>
        </div>
    )
}

export default ViewSubstation